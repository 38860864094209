import VerificationTableGeneral from "./VerificationTableGeneral";

const ContentCreatorTabFragment = (props: any) => {
  return (
    <VerificationTableGeneral
      moderationDateSort={props.moderationDateSort}
      moderationStatusOptions={props.moderationStatusOptions}
      target="CONTENT_CREATOR"
    />
  );
};

export default ContentCreatorTabFragment;
