import React from "react";
import { Grid, Input } from "semantic-ui-react";
import classes from "./AmbassadorFilter.module.css";

interface AmbasssadorFilterProps {
  externalSearch: string;
  changeSearch: (s: string) => void;
}

const AmbassadorFilterBar = (props: AmbasssadorFilterProps) => {
  const onChangeHandler = (v: any) => {
    let valid = v.target.value;
    console.log(valid);
    props.changeSearch(valid);
  };

  return (
    <div className={classes.FilterBar}>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <h3 className={classes.title}>Filter Selection by</h3>
          </Grid.Column>
          <Grid.Column>
            <div className={classes.SearchBar}>
              <h4>Search Names</h4>
              <Input
                placeholder="Ambassadors"
                value={props.externalSearch}
                onChange={onChangeHandler}
                autoFocus="autoFocus"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AmbassadorFilterBar;
