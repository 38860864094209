import CreateSetButton from "../../UI/CreateSetButton/CreateSetButton";
import classes from "./NoSetsPage.module.css";

const NoSetsPage = () => {
  return (
    <div className={classes.MessaegContainer}>
      <h1 className={classes.TextContent}>No Sets Found on this Filter</h1>
      <CreateSetButton />
    </div>
  );
};

export default NoSetsPage;
