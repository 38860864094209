import { Discover } from "../components/Discover";
import PageShell from "../components/UI/PageShell/PageShell";

export const DiscoveryScreen = () => {
  return (
    <PageShell title="Set Up Discover Page">
      <Discover />
    </PageShell>
  );
};

export default DiscoveryScreen;
