interface locationActions {
  LOAD_NEW_LOCATION: string;
  DELETE_DATA: string;
}

export interface ImprovedLocation {
  name?: string;
  formatted_address?: string;
  country_code?: string;
  lng?: number;
  lat?: number;
  photo_reference?: string;
  type?: string;
  geoRadius?: number;
}

const locationActionType: locationActions = {
  LOAD_NEW_LOCATION: "LOAD_NEW_LOCATION",
  DELETE_DATA: "DELETE_DATA",
};

export const loadNewLocation = (data: ImprovedLocation) => ({
  type: locationActionType.LOAD_NEW_LOCATION,
  payload: data,
});

export const deleteLocation = () => ({
  type: locationActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

const locationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case locationActionType.LOAD_NEW_LOCATION:
      return { ...state, data: action.payload };
    case locationActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default locationReducer;
