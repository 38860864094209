import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { NavBarTopCustom } from "../UI/Navbar";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteLocation } from "../../redux/location";
import { deleteCurrentItem, loadCurrentItem } from "../../redux/currentItem";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteImage } from "../../redux/image";
import { Data } from "../Images";
import { deleteBase64 } from "../../redux/base64";
import authorization from "../../authorization";
import { Auth } from "aws-amplify";
import {
  getBase64Buffer,
  getBufferFromFile,
  uploadDirectToS3,
  UploadLevel,
} from "../../uploadToS3";
import AddImage from "./formFields/addImage";
import AddTitle from "./formFields/addTitle";
import AddDescription from "./formFields/addDescription";
import SubmitButton from "./formFields/submitButton";
import AddLocation from "./formFields/addLocation";
import AddGeoFence from "./formFields/addGeoFence";

export const ItemForm = (props: any) => {
  const currentItem = useAppSelector((state) => state.currentItem.data);
  const [isLoaded, setIsLoaded] = useState(true);
  const [itemTitle, setItemTitle] = useState<string>(currentItem?.title || "");
  const [itemDesc, setItemDesc] = useState<string>(
    currentItem?.description || ""
  );

  const [itemImage, setItemImage] = useState<Data>({
    author: "",
    url: "",
    downloadLink: "",
  });

  const [geofence, setGeofence] = useState(currentItem?.geofence || false);
  const [georadius, setGeoRadius] = useState(currentItem?.georence || 250);

  const [base64, setBase64] = useState("");

  const [user, setUser] = useState("");

  Auth.currentSession().then(async (res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    setUser(res.getIdToken().payload.sub);
  });

  const setId = props.match.params.id;
  const snafleAction = useAppSelector(
    (state) => state.currentSet?.data?.snafleAction
  );
  const location = useAppSelector((state) => state.location.data);
  const image = useAppSelector((state) => state.image.data);
  const base64Data = useAppSelector((state) => state.base64.data);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const createSetItemUrl = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/createItem`;

  const createItem = async (event: any) => {
    Auth.currentSession().then(async (res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      setUser(res.getIdToken().payload.sub);
    });

    event.preventDefault();

    let radlocation = { ...location, geoRadius: georadius };

    if (!location) {
      radlocation = {
        name: "Global",
        type: "GLOBAL",
        formatted_address: "GLOBAL",
        geoRadius: georadius,
      };
    }

    let data = {
      setId,
      title: itemTitle,
      description: itemDesc,
      location: radlocation,
      hasImage: itemImage?.author?.length > 0 || base64 ? true : false,
      createdBy: user,
      geofence,
      snafleAction,
    };
    console.log(data);

    const item = await fetch(createSetItemUrl, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        dispatch(deleteLocation());
        dispatch(deleteCurrentSet());
        dispatch(deleteCurrentItem());
        dispatch(deleteBase64());
        dispatch(deleteImage());
        return data;
      });

    if (itemImage?.author?.length > 0) {
      await uploadImage(item.itemId, image);
    } else if (base64Data) {
      await uploadBase64(item.itemId, base64Data);
    }
    setTimeout(() => {
      history.goBack();
    }, 1500);
  };

  const uploadBase64 = async (assetId: string, base64: string) => {
    const data = await getBufferFromFile(base64);
    console.log({ setId });
    await uploadDirectToS3(
      `${assetId}.jpg`,
      data,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: setId,
        sourceId: assetId,
      },
      UploadLevel.PUBLIC
    );
  };

  const uploadImage = async (
    assetId: string,
    data: {
      author: string;
      url: string;
      downloadLink: string;
    }
  ) => {
    const res = await getBase64Buffer(data);

    await uploadDirectToS3(
      `${assetId}.jpg`,
      res,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        sourceId: assetId,
        setIdForItem: setId,
        author: data.author,
        downloadLink: data.downloadLink,
      },
      UploadLevel.PUBLIC
    );
  };

  const handleCheckbox = (data: any) => {
    setGeofence(data.checked);
    dispatch(loadCurrentItem({ ...currentItem, geofence: data.checked }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleRadiusChange();
    }, 700);

    return () => clearTimeout(timer);
  }, [georadius]);

  const handleRadiusChange = () => {
    let temp = georadius;

    if (temp < 100) {
      temp = 100;
    } else if (temp > 2000) {
      temp = 2000;
    }

    setGeoRadius(temp);
    dispatch(loadCurrentItem({ ...currentItem, georadius: georadius }));
  };

  const geoRadiusChange = (r: any) => {
    setGeoRadius(r);
  };

  useEffect(() => {
    setItemImage(image || { author: "", url: "", downloadLink: "" });
    setBase64(base64 || base64Data);
    Auth.currentAuthenticatedUser();
    console.log("user is signed in");
    Auth.currentSession().then(async (res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      setUser(res.getIdToken().payload.sub);
    });
  }, [dispatch]);

  const onTitleChangeHandler = (val: any) => {
    setItemTitle(val);
    dispatch(
      loadCurrentItem({
        ...currentItem,
        title: val,
      })
    );
  };

  const onDescChangeHandler = (val: any) => {
    setItemDesc(val);
    dispatch(
      loadCurrentItem({
        ...currentItem,
        description: val,
      })
    );
  };

  const onSubmitHandler = (val: any) => {
    setIsLoaded(val);
  };

  return (
    <div>
      <NavBarTopCustom buttonLeft="Discard" title="Add Item" buttonRight="" />
      <Form style={{ paddingBottom: "20%" }} onSubmit={createItem}>
        <AddImage itemImage={itemImage} />
        <div className="custom-item-form">
          <AddTitle
            itemTitle={itemTitle}
            onTitleChange={onTitleChangeHandler}
          />
          <AddDescription
            itemDesc={itemDesc}
            onDescChange={onDescChangeHandler}
          />
          <AddLocation location={location} />
          <AddGeoFence
            georadius={georadius}
            geofence={geofence}
            onCheckboxChange={handleCheckbox}
            onRadiusChange={geoRadiusChange}
          />
          <SubmitButton
            buttonText="Create Item"
            isLoaded={isLoaded}
            onSubmit={onSubmitHandler}
          />
        </div>
      </Form>
    </div>
  );
};
