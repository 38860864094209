import VerificationTableGeneral from "./VerificationTableGeneral";

const AmbassadorTabFragment = (props: any) => {
  return (
    <VerificationTableGeneral
      moderationDateSort={props.moderationDateSort}
      moderationStatusOptions={props.moderationStatusOptions}
      target="AMBASSADOR"
    />
  );
};

export default AmbassadorTabFragment;
