import { Form } from "semantic-ui-react";

const AddDescription = (props: any) => {
  const onChangeHandler = (v: any) => {
    let value = v.target.value;
    props.onDescChange(value);
  };

  return (
    <Form.Field required>
      <label>Description</label>
      <textarea
        required={true}
        id="ItemDesc"
        className=""
        name="ItemDesc"
        placeholder="What does you item involve? Include rules, instructions or tips!"
        value={props.itemDesc}
        onChange={onChangeHandler}
        style={{ resize: "none" }}
      ></textarea>
    </Form.Field>
  );
};

export default AddDescription;
