// This is the Summary screen for Viewing all users
// Created by Tony2
// 2022-07-27

import PageShell from "../UI/PageShell/PageShell";
import SetSearch from "../UI/SearchBar/setSearch";

const AllUsers = () => {
  return (
    <PageShell>
      <SetSearch />
    </PageShell>
  );
};

export default AllUsers;
