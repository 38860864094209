import React from "react";
import { Link } from "react-router-dom";
import { Button } from "./cstm-btn.style";

export const CustomButton = (props: {
  buttonTitle: string;
  connection: string;
}) => {
  return (
    <Link to={props.connection}>
      <Button style={{}}>{props.buttonTitle}</Button>
    </Link>
  );
};
