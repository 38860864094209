import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TableRowVerification = (props: {
  username: string;
  timestamp: number;
  status: string;
  reason: string;
  fullName: string;
  documentType: string;
  alsoKnownAs: string;
  audience: string;
  requestCategory: string;
  requestId: string;
  userSub: string;
}) => {
  const location = useLocation();
  // console.log({ userSub: props.username });
  return (
    <Table.Row key={props.requestId}>
      {/* <Table.Cell>{props.username}</Table.Cell> */}
      <Table.Cell>{new Date(props.timestamp).toISOString()}</Table.Cell>
      <Table.Cell>{props.reason}</Table.Cell>
      <Table.Cell>{props.fullName}</Table.Cell>
      <Table.Cell>{props.documentType}</Table.Cell>
      <Table.Cell>{props.alsoKnownAs}</Table.Cell>
      <Table.Cell>{props.audience}</Table.Cell>
      <Table.Cell>{props.requestCategory}</Table.Cell>
      <Table.Cell>{props.status}</Table.Cell>
      <Table.Cell>
        <Link
          to={`${location.pathname}/VER_${props.userSub}_${props.requestId}`}
        >
          View Report
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRowVerification;
