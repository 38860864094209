import { Button } from "semantic-ui-react";

const DeleteItemButton = (props: any) => {
  return (
    <Button
      style={{
        width: "100%",
        backgroundColor: "#D90D00",
        color: "white",
        borderRadius: 8,
        margin: "10% 0",
      }}
      type="button"
      onClick={props.onDeleteHandler}
    >
      Delete Item
    </Button>
  );
};

export default DeleteItemButton;
