import { Table } from "semantic-ui-react";
import TableRowSocialMedia from "../TableRows/tableRowSocialMedia";

interface SocialMediaTableProps {
  currentReport: any;
}

const SocialMediaTable = (props: SocialMediaTableProps) => {
  return (
    <>
      <h2 style={{ margin: "10% 0 0 0" }}>Social Media Accounts</h2>
      <Table definition celled striped>
        <Table.Body>
          <TableRowSocialMedia {...props.currentReport} />
        </Table.Body>
      </Table>
    </>
  );
};

export default SocialMediaTable;
