interface ParragrapherProps {
  text: string;
}

const Parragrapher = (props: ParragrapherProps) => {
  const broken = props.text.split(/\r?\n/);

  return (
    <div>
      {broken.map((textLine) => (
        <p>{textLine}</p>
      ))}
    </div>
  );
};

export default Parragrapher;
