import { useEffect, useState } from "react";
import { Button, Modal, Checkbox } from "semantic-ui-react";
import authorization from "../../../authorization";

const MaintenanceModal = () => {
  const [open, setOpen] = useState(false);
  const [appState, setAppState] = useState(false);

  useEffect(() => {
    getMaintenanceMode();
  });

  const getMaintenanceMode = async () => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT3}getAppMode`, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setAppState(data?.maintenanceMode);
      });
  };

  const setMaintenanceMode = async (data: boolean) => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT3}setAppMode`, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        maintenanceMode: data,
      }),
    }).then((res) => console.log(res.status));
  };

  const maintainanceYes = () => {
    setOpen(false);
    setMaintenanceMode(!appState);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Checkbox toggle checked={appState} />}
    >
      <Modal.Header>Maintainance Mode</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          {!appState && (
            <p>
              This will take the production app down and place it in
              maintainance mode
            </p>
          )}
          {appState && <p>This will return the App to normal made</p>}
          <p>Are you sure</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => setOpen(false)}>
          No
        </Button>
        <Button
          content="Yes"
          labelPosition="right"
          icon="checkmark"
          onClick={maintainanceYes}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
export default MaintenanceModal;
