import classes from "./searchStyles.module.css";
import { Input } from "semantic-ui-react";
import { useState } from "react";

const SetSearch = (props: any) => {
  const [searchValue, setSearchValue] = useState("");

  const onSearchHandler = () => {
    props.onSearch(searchValue);
  };

  const changeSearchHandler = (e: any, item: any) => {
    console.log(item.value);
    setSearchValue(item.value);
    console.log(searchValue);
  };

  return (
    <div className={classes.tophold}>
      <Input
        className={classes.textInput}
        type="text"
        placeholder="Search Sets"
        value={searchValue}
        onChange={changeSearchHandler}
        action={{ icon: "search", onClick: onSearchHandler, color: "orange" }}
      />
    </div>
  );
};

export default SetSearch;
