import React, { useEffect, useState } from "react";
import { NavBar } from "..";
import "./moderation.css";
import { Tab } from "semantic-ui-react";
import { Storage } from "aws-amplify";
import authorization from "../../authorization";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPreviousTabM } from "../../redux/previousModeration";
import { ReportTable, ModerationTable } from "./TablesFull";

const Moderation = () => {
  const [modCount, setModCount] = useState(0);
  const [repCount, setRepCount] = useState(0);

  const HumanStatusOptions = [
    { key: "ALL", value: "ALL", text: "ALL" },
    {
      key: "REJECTED",
      value: "REJECTED",
      text: "REJECTED",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
      text: "ACCEPTED",
    },
    {
      key: "PENDING",
      value: "PENDING",
      text: "PENDING",
    },
  ];

  const AIStatusOptions = [
    { key: "ALL", value: "ALL", text: "ALL" },
    {
      key: "REJECTED",
      value: "REJECTED",
      text: "REJECTED",
    },
    {
      key: "REASON",
      value: "REASON",
      text: "PENDING",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
      text: "ACCEPTED",
    },
  ];

  const moderationSourceType = [
    { key: "ALL", value: "ALL", text: "ALL" },
    { key: "ITEM", value: "ITEM", text: "ITEM" },
    {
      key: "SET",
      value: "SET",
      text: "SET",
    },
    {
      key: "PROFILE_IMAGE",
      value: "PROFILE_IMAGE",
      text: "PROFILE_IMAGE",
    },
    {
      key: "PROFILE_THUMBNAIL",
      value: "PROFILE_THUMBNAIL",
      text: "PROFILE_THUMBNAIL",
    },
    {
      key: "PROFILE_COVER",
      value: "PROFILE_COVER",
      text: "PROFILE_COVER",
    },
    {
      key: "POST",
      value: "POST",
      text: "POST",
    },
  ];

  const contentSourceTypes = [
    { key: "ALL", value: "ALL", text: "ALL" },
    { key: "ITEM", value: "ITEM", text: "ITEM" },
    {
      key: "SET",
      value: "SET",
      text: "SET",
    },
    {
      key: "POST",
      value: "POST",
      text: "POST",
    },
    {
      key: "PROFILE",
      value: "PROFILE",
      text: "PROFILE",
    },
    {
      key: "COMMENT",
      value: "COMMENT",
      text: "COMMENT",
    },
  ];

  const moderationDateSort = [
    { key: "NEW", value: "NEWEST", text: "NEWEST-OLDEST" },
    { key: "OLDEST", value: "OLDEST", text: "OLDEST-NEWEST" },
    { key: "NORM", value: "NORM", text: "NORM" },
  ];

  //Logic for keep tack of tab for returning to the page
  const dispatch = useAppDispatch();
  const previousTab = useAppSelector(
    (state) => state.moderationPrevious.previousModerationTab
  );
  const TabChangeHandler = (e: any, data: any) => {
    dispatch(setPreviousTabM(data.activeIndex));
  };

  const getModerationData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationData`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setModCount(res.items.length);
      });
  };

  const getReports = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setRepCount(res.items.length);
      });
  };

  useEffect(() => {
    getReports();
    getModerationData();
  }, []);

  Storage.configure({
    customPrefix: {
      public: "snaflerPublic/",
      protected: "snaflerProtected/",
      private: "snaflerPrivate/",
    },
  });

  const panes = [
    {
      menuItem: `AI Generated Reports (${modCount})`,
      render: () => (
        <ModerationTable
          moderationDateSort={moderationDateSort}
          moderationSourceType={moderationSourceType}
          moderationStatusOptions={AIStatusOptions}
        />
      ),
    },
    {
      menuItem: `User Submitted Reports (${repCount})`,
      render: () => (
        <ReportTable
          contentSourceTypes={contentSourceTypes}
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={HumanStatusOptions}
        />
      ),
    },
  ];

  return (
    <div className="mod-main-conatiner">
      <Tab
        panes={panes}
        renderActiveOnly={true}
        defaultActiveIndex={previousTab}
        onTabChange={TabChangeHandler}
      />
    </div>
  );
};

export default Moderation;
