import authorization from "../../../authorization";
import { useHistory } from "react-router";
import ButtonGeneral from "./buttonAcceptance";
import classes from "./ReportButtons.module.css";

interface ButtonHolderProps {
  currentReportType: string;
  currentReport: any;
  modComments: string;
  dropDown: string;
}

const ButtonHolder = (props: ButtonHolderProps) => {
  const history = useHistory();

  const updateModeration = async (
    userSub: string,
    sourceId: string,
    status: "ACCEPTED" | "REVIEW" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    sourceType: string
  ) => {
    const body = {
      userSub,
      sourceId,
      status,
      moderatorComments: `${moderatorComments} ${dropDowncomments}`,
      sourceType,
    };
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}updateModerationReport`,
      {
        method: "PUT",
        headers: { ...(await authorization()) },
        body: JSON.stringify(body),
      }
    );
    setTimeout(() => {
      history.goBack();
    });
  };

  const updateReport = async (
    userSub: string,
    sourceId: string,
    status: "ACCEPTED" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    sourceType: string
  ) => {
    const body = {
      userSub,
      sourceId,
      status,
      moderatorComments: `${moderatorComments} ${dropDowncomments}`,
      sourceType,
    };
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}updateReport`, {
      method: "PUT",
      headers: { ...(await authorization()) },
      body: JSON.stringify(body),
    });
    setTimeout(() => {
      history.goBack();
    });
  };

  const updateCCPR = async (
    userSub: string,
    requestId: string,
    status: "ACCEPTED" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    type: string
  ) => {
    const body = {
      userSub,
      requestId,
      status,
      moderatorComments: `${moderatorComments}. -> ${dropDowncomments}`,
      type,
    };

    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}updateCCPR`, {
      method: "PUT",
      headers: { ...(await authorization()) },
      body: JSON.stringify(body),
    });
    setTimeout(() => {
      history.goBack();
    });
  };

  return (
    <div className={classes.ButtonHolder}>
      {props.currentReportType === "REP" && (
        <div>
          <ButtonGeneral
            btnText="Accept Content"
            btnType="ACCEPTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button green"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.sourceType}
            updateFunction={updateReport}
            userSub={props.currentReport?.userSub}
          />
          <ButtonGeneral
            btnText="Reject Content"
            btnType="REJECTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button orange"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.sourceType}
            updateFunction={updateReport}
            userSub={props.currentReport?.userSub}
          />
        </div>
      )}
      {props.currentReportType === "VER" && (
        <div>
          <ButtonGeneral
            btnText="Accept Verification"
            btnType="ACCEPTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button green"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.type}
            updateFunction={updateCCPR}
            userSub={props.currentReport?.userSub}
          />
          <ButtonGeneral
            btnText="Decline Verification"
            btnType="REJECTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button orange"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.type}
            updateFunction={updateCCPR}
            userSub={props.currentReport?.userSub}
          />
        </div>
      )}
      {props.currentReportType === "MOD" && (
        <div>
          <ButtonGeneral
            btnText="Accept Image"
            btnType="ACCEPTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button green"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.sourceType}
            updateFunction={updateModeration}
            userSub={props.currentReport?.userSub}
          />
          <ButtonGeneral
            btnText="Reject Image"
            btnType="REJECTED"
            dropdown={props.dropDown}
            modComments={props.modComments}
            renderType="mod-button orange"
            sourceId={props.currentReport?.sourceId}
            type={props.currentReport?.sourceType}
            updateFunction={updateModeration}
            userSub={props.currentReport?.userSub}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonHolder;
