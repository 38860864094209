import { useState } from "react";
import { Sidebar, Menu } from "semantic-ui-react";
import NavBarSide from "../Navbar/navbarSidebar";
import classes from "./PageShell.module.css";

const PageShell = (props: any) => {
  const [visable, setVisable] = useState(false);
  const [dimmed, setDimmed] = useState(false);

  const onBurgerClick = () => {
    setVisable(!visable);
    setDimmed(!dimmed);
  };

  return (
    <div>
      <NavBarSide text={props.title} onBurgerClick={onBurgerClick} />
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          visible={visable}
          vertical
          width={"wide"}
        >
          <div className={classes.SideBar}>
            <Menu.Item href="/">
              <h2>Home Page</h2>
            </Menu.Item>
            <Menu.Item href="/home">
              <h2>Sets and Items</h2>
            </Menu.Item>
            <Menu.Item href="/discoverData">
              <h2>Discover</h2>
            </Menu.Item>
            <Menu.Item href="/addActivities">
              <h2>Activities</h2>
            </Menu.Item>
            <Menu.Item href="/addCategories">
              <h2>Categories</h2>
            </Menu.Item>
            <Menu.Item href="/Moderation">
              <h2>Content Moderation</h2>
            </Menu.Item>
            <Menu.Item href="/UserFeedback">
              <h2>User Feedback</h2>
            </Menu.Item>
            <Menu.Item href="/ContentCreator">
              <h2>Content Creator Requests</h2>
            </Menu.Item>
            <Menu.Item href="/stats">
              <h2>Stats</h2>
            </Menu.Item>
          </div>
        </Sidebar>
        <Sidebar.Pusher dimmed={dimmed}>
          <div className={classes.Content}>{props.children}</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default PageShell;
