import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "semantic-ui-react";
import { NavBarTopCustom } from "../UI/Navbar";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteCurrentItem, loadCurrentItem } from "../../redux/currentItem";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteLocation } from "../../redux/location";
import { Data } from "../Images";
import { deleteImage } from "../../redux/image";
import { deleteBase64 } from "../../redux/base64";
import authorization from "../../authorization";
import {
  getBase64Buffer,
  getBufferFromFile,
  uploadDirectToS3,
  UploadLevel,
} from "../../uploadToS3";
import { Auth } from "aws-amplify";
import AddImage from "./formFields/addImage";
import SubmitButton from "./formFields/submitButton";
import AddTitle from "./formFields/addTitle";
import AddDescription from "./formFields/addDescription";
import AddLocation from "./formFields/addLocation";
import AddGeoFence from "./formFields/addGeoFence";
import DeleteItemButton from "./formFields/deleteItemButton";

export const EditItemForm = (props: any) => {
  const url = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/getSetItem?id=${props.match.params.id}`;
  const dispatch = useAppDispatch();
  const currentItem = useAppSelector((state) => state.currentItem.data);
  const [itemTitle, setItemTitle] = useState<string>(currentItem?.title || "");
  const [itemDesc, setItemDesc] = useState<string>("");
  const [user, setUser] = useState("");

  Auth.currentSession().then(async (res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    setUser(res.getIdToken().payload.sub);
  });

  const [itemImage, setItemImage] = useState<Data>({
    author: "",
    url: "",
    downloadLink: "",
  });

  const [base64, setBase64] = useState("");
  const [geofence, setGeofence] = useState(currentItem?.geofence || false);
  const [geoRadius, setGeoRadius] = useState(
    currentItem?.location.geoRadius || 250
  );

  const image = useAppSelector((state) => state.image.data) || {
    author: "",
    url: "",
    downloadLink: "",
  };
  const base64Data = useAppSelector((state) => state.base64.data);

  const [location, setLocation] = useState({
    name: "",
    formatted_address: "",
    type: "",
  });

  const currentLocation = useAppSelector((state) => state.location.data);

  useEffect(() => {
    setItemTitle(currentItem?.title);
    setItemDesc(currentItem?.description);
    setLocation(currentLocation || currentItem?.location);
    setItemImage(image || currentItem?.image);
    setBase64(base64Data);
    setGeofence(currentItem?.geofence || false);
    setGeoRadius(currentItem?.location.geoRadius || 250);
  }, []);

  const createSetItemUrl = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/updateItem`;

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  const editItem = async (event: any) => {
    let radlocation = { ...location, geoRadius: geoRadius };

    if (!location) {
      radlocation = {
        name: "Global",
        type: "GLOBAL",
        formatted_address: "GLOBAL",
        geoRadius: geoRadius,
      };
    }

    event.preventDefault();
    let data = {
      setId: currentItem.setId,
      itemId: currentItem.itemId,
      title: itemTitle,
      description: itemDesc,
      location: radlocation,
      hasImage: itemImage?.author?.length > 0 || base64 ? true : false,
      geofence,
    };
    console.log(data);

    await fetch(createSetItemUrl, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => console.log(data))
      .then(() => {
        dispatch(deleteLocation());
        dispatch(deleteCurrentSet());
        dispatch(deleteCurrentItem());
        dispatch(deleteImage());
        dispatch(deleteBase64());
      });
    console.log({ itemImage, image, base64Data });
    if (itemImage?.author?.length > 0) {
      await uploadImage(image);
    } else if (base64Data) {
      await uploadBase64(base64Data);
    }

    setTimeout(() => {
      history.goBack();
    }, 1500);
  };

  const deleteItem = async () => {
    const url = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/deleteItem`;

    const data = {
      id: currentItem.setId,
      itemId: currentItem.itemId,
    };
    console.log(data);
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  const uploadBase64 = async (base64: string) => {
    const data = await getBufferFromFile(base64);
    console.log(props.match.params.id);
    await uploadDirectToS3(
      `${props.match.params.id}.jpg`,
      data,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: currentItem.setId,
        sourceId: props.match.params.id,
      },
      UploadLevel.PUBLIC
    );
  };

  const uploadImage = async (data: {
    author: string;
    url: string;
    downloadLink: string;
  }) => {
    const res = await getBase64Buffer(data);
    await uploadDirectToS3(
      `${props.match.params.id}.jpg`,
      res,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: currentItem.setId,
        sourceId: props.match.params.id,
        author: data.author,
        downloadLink: data.downloadLink,
      },
      UploadLevel.PUBLIC
    );
  };

  const handleCheckbox = (data: any) => {
    setGeofence(data.checked);
    dispatch(loadCurrentItem({ ...currentItem, geofence: data.checked }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleRadiusChange();
    }, 1000);

    return () => clearTimeout(timer);
  }, [geoRadius]);

  const handleRadiusChange = () => {
    let temp = geoRadius;

    if (temp < 100) {
      temp = 100;
    } else if (temp > 2000) {
      temp = 2000;
    }

    setGeoRadius(temp);
    dispatch(loadCurrentItem({ ...currentItem, georadius: geoRadius }));
  };

  const geoRadiusChange = (r: any) => {
    setGeoRadius(r);
  };

  const onSubmitHandler = (val: any) => {
    setIsLoaded(val);
  };

  const onTitleChangeHandler = (val: any) => {
    setItemTitle(val);
    dispatch(
      loadCurrentItem({
        ...currentItem,
        title: val,
      })
    );
  };

  const onDescChangeHandler = (val: any) => {
    setItemDesc(val);
    dispatch(
      loadCurrentItem({
        ...currentItem,
        description: val,
      })
    );
  };

  const onDeleteHandler = () => {
    deleteItem();
    history.push(`/set/${currentItem.setId}`);
  };

  return (
    <div>
      <NavBarTopCustom buttonLeft="Discard" title="Edit Item" buttonRight="" />
      <Form style={{ paddingBottom: "20%" }} onSubmit={editItem}>
        <AddImage itemImage={itemImage} />
        <div className="custom-item-form">
          <Form.Field required>
            <AddTitle
              itemTitle={itemTitle}
              onTitleChange={onTitleChangeHandler}
            />
          </Form.Field>
          <AddDescription
            itemDesc={itemDesc}
            onDescChange={onDescChangeHandler}
          />
          <AddLocation location={location} />
          <AddGeoFence
            georadius={geoRadius}
            geofence={geofence}
            onCheckboxChange={handleCheckbox}
            onRadiusChange={geoRadiusChange}
          />
          <Form.Field>
            <SubmitButton
              buttonText="Confirm Edit"
              isLoaded={isLoaded}
              onSubmit={onSubmitHandler}
            />
            <DeleteItemButton onDeleteHandler={onDeleteHandler} />
          </Form.Field>
        </div>
      </Form>
    </div>
  );
};
