import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import authorization from "../../authorization";
import { useAppSelector } from "../../hooks";
import RenderComponent from "./categoryCard";

type Category = { id: string; name: string };

export const AddCatComp = () => {
  const categories = useAppSelector((state) => state.category.data);
  const [cat, setCat] = useState([{ id: "", name: "", imageUrl: "" }]);
  const [value, setValue] = useState("");
  const [responseText, setResponseText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [newData, setNewData] = useState(false);
  const compareStrings = (a: string, b: string) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    let categoriesSorted = categories;
    categoriesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });
    setCat(categoriesSorted);
  }, [categories]);

  useEffect(() => {
    const getCategories = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getCategories`;
      await fetch(url, { headers: { ...(await authorization()) } })
        .then((data) => data.json())
        .then((data) => {
          setCat(
            data.sort((a: Category, b: Category) => {
              return compareStrings(a.name, b.name);
            })
          );
        });
    };
    getCategories();
  }, []);

  const addCategory = async () => {
    const body = {
      name: value,
    };
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}createCategory`;
    const data = await fetch(url, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(async (data) => {
      if (data.status === 201) {
        setResponseText("Category inserted into the database");
        setErrorText("");
        setCat([{ id: "", name: value, imageUrl: "" }, ...cat]);
        setNewData(!newData);
      } else {
        setResponseText("");
        setErrorText("Category already exists");
      }
    });
  };

  const deleteCategory = async (id: string) => {
    const body = {
      id,
    };

    const url = `https://q71uh9trvd.execute-api.eu-west-1.amazonaws.com/dev/deleteCategory`;
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((data) => {
      setNewData(!newData);
    });
  };

  const InputChangeHandler = (item: any) => {
    setValue(item.target.value);
  };

  return (
    <div>
      <div className="new-category">
        <Input
          key="AutocompleteId"
          id="AutocompleteId"
          placeholder="Add Categories"
          style={{ paddingTop: "10%" }}
          value={value}
          size="large"
          onChange={InputChangeHandler}
          action={{
            icon: "plus",
            color: "orange",
            onClick: () => {
              addCategory();
            },
          }}
        />
        <p className="smallText" style={{ color: "green" }}>
          {responseText}
        </p>
        <p className="smallText" style={{ color: "red" }}>
          {errorText}
        </p>
      </div>
      <div className="categories-container">
        <h4 style={{ textAlign: "center" }}>Current Categories</h4>
        <div className="current-cat-container">
          {cat.map((index) => (
            <RenderComponent item={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
