import Home from "../components/SetsAndItems/home";
import PageShell from "../components/UI/PageShell/PageShell";

export const SetsAndItems = () => {
  return (
    <PageShell title="Sets and Items">
      <Home />
    </PageShell>
  );
};

export default SetsAndItems;
