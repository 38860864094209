import { NavbarContainer } from "./navbar.styles";
import LogoutButton from "./logoutButton.component";
import SnaflerHome from "./snaflerHome.component";
import { Icon } from "semantic-ui-react";

export const NavBarSide = (props: {
  text: string;
  onBurgerClick: () => void;
}) => {
  return (
    <NavbarContainer>
      <Icon
        name="align justify"
        onClick={props.onBurgerClick}
        size="big"
        className="burger-selector"
      />
      <SnaflerHome />
      <div className="nav-web">
        <div style={{ paddingTop: "1%", paddingRight: "2%" }}>
          <LogoutButton />
        </div>
        <div style={{ marginLeft: "40%" }}>
          <h1>{props.text}</h1>
        </div>
      </div>
    </NavbarContainer>
  );
};

export default NavBarSide;
