import logo from "../../../assets/snafler-header-logo.png";
import { useHistory } from "react-router-dom";

const SnaflerHome = () => {
  const history = useHistory();

  return (
    <img
      src={logo}
      alt="snafler"
      className="logo"
      onClick={() => {
        history.push("/");
      }}
    />
  );
};

export default SnaflerHome;
