interface contentcreatorsPreviousActions {
  SET_PREVIOUS_CONTENT: string;
  INVALID_TAB_NO: string;
}

const contentCreatorsPreviousActionsType: contentcreatorsPreviousActions = {
  SET_PREVIOUS_CONTENT: "SET_PREVIOUS_CONTENT",
  INVALID_TAB_NO: "INVALID_TAB_NO",
};

const INITIAL_STATE = {
  previousContentCreatorTab: 0,
};

export const setPreviousTabC = (data: number) => {
  if (data >= 0 && data <= 4) {
    return {
      type: contentCreatorsPreviousActionsType.SET_PREVIOUS_CONTENT,
      payload: data,
    };
  } else {
    return {
      type: contentCreatorsPreviousActionsType.INVALID_TAB_NO,
      payload: 0,
    };
  }
};

const contentCreatorPreviousReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case contentCreatorsPreviousActionsType.SET_PREVIOUS_CONTENT:
      return { previousContentCreatorTab: action.payload };
    case contentCreatorsPreviousActionsType.INVALID_TAB_NO:
      return state;
    default:
      return state;
  }
};

export default contentCreatorPreviousReducer;
