import React from "react";
import { Table } from "semantic-ui-react";
import ReportTableRow from "./tableRow";

const ReportTable = (props: { data: any }) => {
  return (
    <Table definition celled striped>
      <Table.Body>
        <ReportTableRow data={props.data} />
      </Table.Body>
    </Table>
  );
};

export default ReportTable;
