import React, { useEffect, useState } from "react";
import authorization from "../../authorization";
import { NavBarTopCustom } from "../UI/Navbar";
import ReportTable from "./table";

const ViewUserFeedback = (props: any) => {
  const [report, setReport] = useState();

  const getReport = async () => {
    const url = props.match.params.reportId.split("_");
    const source = url[0];
    const userSub = url[1];
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getReport?sourceId=${source}&userSub=${userSub}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((data) => data.json())
      .then((json) => {
        console.log({ json });
        setReport(json);
      });
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <NavBarTopCustom title="View User Feedback" />
      <div className="mod-main-conatiner">
        <div className="mod-main-flex">
          <h2>Report Details</h2>
          <ReportTable data={report} />
        </div>
      </div>
    </>
  );
};

export default ViewUserFeedback;
