// necessary imports //

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import base64Reducer from "./base64";
import catReducer from "./categories";
import currentItemReducer from "./currentItem";
import currentSetReducer from "./currentSet";
import imageReducer from "./image";
import locationReducer from "./location";
import actReducer from "./activity";
import selectionReducer from "./selection";

// reducers
import setReducer from "./sets";
import userReducer from "./user";
import discoverReducer from "./discover";
import currentCatActReducer from "./currentCatAct";
import previouspageReducer from "./previouspage";
import feedbackPreviousReducer from "./previousUserFeed";
import discoverPreviousReducer from "./previousDiscover";
import moderationPreviousReducer from "./previousModeration";
import contentCreatorPreviousReducer from "./previousContentCreators";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  set: setReducer,
  location: locationReducer,
  category: catReducer,
  user: userReducer,
  currentSet: currentSetReducer,
  currentItem: currentItemReducer,
  image: imageReducer,
  base64: base64Reducer,
  activity: actReducer,
  discover: discoverReducer,
  currentCatAct: currentCatActReducer,
  selection: selectionReducer,
  previouspage: previouspageReducer,
  feedbackPrevious: feedbackPreviousReducer,
  discoverPrevious: discoverPreviousReducer,
  moderationPrevious: moderationPreviousReducer,
  contentCreatorPrevious: contentCreatorPreviousReducer,
});

export default persistReducer(persistConfig, rootReducer);
