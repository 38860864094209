import { useEffect, useState } from "react";
import { Tab, Table, Dropdown, Button } from "semantic-ui-react";
import TableRowsReport from "../TableParts/TableRowsReport";
import authorization from "../../../authorization";

interface ReportTableProps {
  contentSourceTypes: any;
  moderationDateSort: any;
  moderationStatusOptions: any;
}

export const ReportTable = (props: ReportTableProps) => {
  const [dataRaw, setDataRaw] = useState<any>([]);
  const [dataDisplay, setDataDisplay] = useState<any>([]);
  const [moderationStatus, setModerationStatus] = useState("ALL");
  const [sourceType, setSourceType] = useState<any>("ALL");
  const [modTime, setModTime] = useState("NORM");

  const handleModerationTimeSort = (event: any, item: any) => {
    orderTime("timestamp", item.value);
    setModTime(item.value);
  };

  const orderTime = (
    attributeName: string,
    type: "NEWEST" | "OLDEST" | "NORM"
  ) => {
    if (
      modTime !== "NORM" ||
      moderationStatus !== "ALL" ||
      sourceType !== "ALL"
    ) {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] < b[attributeName]
              ? -1
              : a[attributeName] > b[attributeName]
              ? 1
              : 0
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] > b[attributeName]
              ? -1
              : a[attributeName] < b[attributeName]
              ? 1
              : 0
          )
        );
      }
    } else {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] < b[attributeName]
              ? -1
              : a[attributeName] > b[attributeName]
              ? 1
              : 0
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] > b[attributeName]
              ? -1
              : a[attributeName] < b[attributeName]
              ? 1
              : 0
          )
        );
      }
    }
  };

  const handleSourceTypeChange = (event: any, item: any) => {
    setSourceType(item.value);
  };

  const handleStatusChange = (event: any, item: any) => {
    setModerationStatus(item.value);
  };

  const getReports = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setDataRaw(res.items);
        setDataDisplay(res.items);
      });
  };

  const filterDisplay = () => {
    let temp = dataRaw;

    if (sourceType !== "ALL") {
      temp = temp.filter((line: any) => line.sourceType === sourceType);
    }

    if (moderationStatus !== "ALL") {
      temp = temp.filter((line: any) => line.status === moderationStatus);
    }

    setDataDisplay(temp);
  };

  useEffect(() => {
    filterDisplay();
  }, [sourceType, moderationStatus]);

  useEffect(() => {
    getReports();
  }, []);

  return (
    <Tab.Pane>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Source Type
              <div>
                <Dropdown
                  options={props.contentSourceTypes}
                  selection
                  fluid
                  value={sourceType}
                  onChange={handleSourceTypeChange}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Report Category
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Report Timestamp
              <div>
                <Dropdown
                  options={props.moderationDateSort}
                  selection
                  fluid
                  value={modTime}
                  onChange={handleModerationTimeSort}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={4}>
              User Message
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Request Status
              <div>
                <Dropdown
                  options={props.moderationStatusOptions}
                  selection
                  fluid
                  value={moderationStatus}
                  onChange={handleStatusChange}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              View
              <div>
                <Button
                  primary
                  onClick={() => {
                    setDataDisplay(dataRaw);
                    setModTime("NORM");

                    setSourceType("ALL");
                    setModerationStatus("ALL");
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <TableRowsReport data={dataDisplay} />
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

export default ReportTable;
