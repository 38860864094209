import PageShell from "../components/UI/PageShell/PageShell";
import UserFeedBack from "../components/UserFeedback/userFeedback";

export const UserFeedbackScreen = () => {
  return (
    <PageShell title="User Feedback">
      <UserFeedBack />
    </PageShell>
  );
};

export default UserFeedbackScreen;
