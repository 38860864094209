import React, { useEffect, useState } from "react";
import { Menu } from "../UI/Menu/menu.component";
import { useAppDispatch } from "../../hooks";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import { deleteLocation } from "../../redux/location";
import { deleteImage } from "../../redux/image";
import { deleteBase64 } from "../../redux/base64";
import { Link } from "react-router-dom";
import authorization from "../../authorization";
import FilterBar from "./FilterBar/FilterBar";
import CreateSetButton from "../UI/CreateSetButton/CreateSetButton";
import classes from "./home.module.css";
import { Pagination } from "semantic-ui-react";

const Home = () => {
  const [data, setData] = useState<any>([]);
  const [userDrafts, setUserDrafts] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState(true);
  const [currentCategory, setCurrentCategory] = useState("0");
  const [currentActivities, setCurrentActivities] = useState("AllActivities");
  const [currentStatus, setCurrentStatus] = useState("PUBLISHED");
  const [currentPro, setCurrentPro] = useState("ALL");
  const [displayData, setDisplayData] = useState([]);
  const [titleSearch, setTitleSearch] = useState("");

  const [advSearch, setAdvSearch] = useState(false);
  const [setQuery, setSetQuery] = useState(
    `${process.env.REACT_APP_AWS_ENDPOINT2}getSets`
  );

  const [pageCount, setPageCount] = useState(0);
  const dispatch = useAppDispatch();

  const getSets = async () => {
    if (currentUser === true) {
      await fetch(setQuery, {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsonData) => {
          if (!advSearch) {
            setData(
              jsonData.sets.map((data: any) => ({
                id: data.id,
                title: data.title,
                description: data.description,
                numItems: data.numItems,
                activityType: data.activityType,
                categoryId: data.categoryId,
                status: data.status,
                subscriptionType: data.subscriptionType,
              }))
            );
          } else {
            setData(
              jsonData.results.map((data: any) => ({
                id: data.id,
                title: data.title,
                description: data.description,
                numItems: data.numItems,
              }))
            );
          }
        });
    }
    await getDraftSets();
  };

  const filterSets = (sets: any) => {
    let filterTemp = sets;

    if (!(currentActivities === "" || currentActivities === "AllActivities")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.activityType === currentActivities
      );
    }
    if (!(currentCategory === "" || currentCategory === "0")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.categoryId === currentCategory
      );
    }

    if (!(currentStatus === "" || currentStatus === "ANY")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.status === currentStatus
      );
    }

    if (currentPro === "FREE") {
      filterTemp = filterTemp.filter(
        (set: any) =>
          set.subscriptionType === "FREE" || set.subscriptionType === undefined
      );
    } else if (!(currentPro === "" || currentPro === "ALL")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.subscriptionType === currentPro
      );
    } else {
      console.log("No pro filter");
    }

    if (titleSearch.trim() !== "") {
      filterTemp = filterTemp.filter((set: any) =>
        set.title.toLowerCase().includes(titleSearch.toLowerCase())
      );
    }

    setPageCount(Math.round(filterTemp.length / 50));
    console.log(filterTemp);
    return filterTemp;
  };

  useEffect(() => {
    setDisplayData(filterSets(data));
  }, [
    currentActivities,
    currentCategory,
    currentStatus,
    currentPro,
    data,
    titleSearch,
  ]);

  const getDraftSets = async () => {
    if (currentUser === false) {
      await fetch(
        `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/getDraftSets`,
        {
          method: "GET",
          headers: {
            ...(await authorization()),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((jsonData) => {
          setUserDrafts(
            jsonData.items.map((data: any) => ({
              id: data.id,
              title: data.title,
              description: data.description,
              numItems: data.numItems,
            }))
          );
        });
    }
  };

  const resetFilters = () => {
    setCurrentUser(true);
    setCurrentCategory("0");
    setCurrentActivities("AllActivities");
    setCurrentStatus("PUBLISHED");
    setCurrentPro("ALL");
    setTitleSearch("");
  };

  const handleUserChange = (value: any) => setCurrentUser(value);
  const handleCategoryChange = (value: any) => setCurrentCategory(value);
  const handleActivityChange = (value: any) => setCurrentActivities(value);
  const handleStatusChange = (value: any) => setCurrentStatus(value);
  const handleProChange = (value: any) => setCurrentPro(value);
  const handleTitleSearchChange = (value: any) => setTitleSearch(value);

  const searchSetHandler = (searchItem: string) => {
    if (searchItem.trim() === "") {
      setAdvSearch(false);
      setSetQuery(`${process.env.REACT_APP_AWS_ENDPOINT2}getSets?`);
    } else {
      setAdvSearch(true);
      setSetQuery(
        `https://api-qa.snafler.com/discover/setSearch?query=${searchItem}&size=20&start=0`
      );
    }
  };

  useEffect(() => {
    getSets();
    dispatch(deleteLocation());
    dispatch(deleteCurrentSet());
    dispatch(deleteCurrentItem());
    dispatch(deleteImage());
    dispatch(deleteBase64());
  }, [currentUser, advSearch]);

  return (
    <div>
      <FilterBar
        onUserChange={handleUserChange}
        currentUser={currentUser}
        searchSetHandler={searchSetHandler}
        currentCategory={currentCategory}
        onCategoryChange={handleCategoryChange}
        onActivityChange={handleActivityChange}
        currentActivity={currentActivities}
        onProChange={handleProChange}
        currentPro={currentPro}
        onStatusChange={handleStatusChange}
        currentStatus={currentStatus}
        currentTitleSearch={titleSearch}
        onTitleSearchChange={handleTitleSearchChange}
        resetFilters={resetFilters}
      />

      {displayData.length > 0 || userDrafts.length > 0 ? (
        <div className={classes.MenuContainer}>
          <div className={classes.ButtonArrange}>
            <CreateSetButton />
          </div>
          <Menu
            setId=""
            data={currentUser ? displayData : userDrafts}
            dataPassThrough="set"
          />
        </div>
      ) : (
        <div className="noset">
          <Link to="/createSnafleSet" style={{ color: "var(--primary-color)" }}>
            <h2>Create your first set!</h2>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Home;
