import { Dropdown } from "semantic-ui-react";

const DatesDropdown = (props: {
  currentDate: string;
  changeHandle: (date: string) => void;
}) => {
  const now = new Date();

  const getArrayOfWeekBeginings = (
    date: number
  ): { key: string; text: string; value: number }[] => {
    let dates: { key: string; text: string; value: number }[] = [];

    let previousDates: { key: string; text: string; value: number }[] = [
      {
        key: new Date(date).toLocaleDateString("en-GB"),
        text:
          new Date(date).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }) + " (Now)",
        value: date,
      },
    ];

    //why do we need previopus dates so far back
    for (let i = 1; i < 10; i++) {
      previousDates.push({
        key: new Date(
          previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB"),
        text: new Date(
          previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        value: previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000,
      });
    }

    dates = [
      ...previousDates.sort(
        (
          a: { key: string; text: string; value: number },
          b: { key: string; text: string; value: number }
        ) => a.value - b.value
      ),
    ];
    for (let i = 10; i < 35; i++) {
      dates.push({
        key: new Date(
          dates[i - 1].value + 7 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB"),
        text: new Date(
          dates[i - 1].value + 7 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        value: dates[i - 1].value + 7 * 24 * 60 * 60 * 1000,
      });
    }

    return dates.sort(
      (
        a: { key: string; text: string; value: number },
        b: { key: string; text: string; value: number }
      ) => a.value - b.value
    );
  };

  const getWeekBegining = (date: number): Date => {
    let d = new Date(date);
    let day = d.getDay();
    let diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  };

  const weekBegining = getArrayOfWeekBeginings(
    getWeekBegining(now.getTime()).getTime()
  );

  const handleChange = (event: any) => {
    let valid = event.target.outerText;
    console.log({ valid });
    if (valid.length > 20) {
      props.changeHandle(props.currentDate);
    } else {
      props.changeHandle(valid);
    }
    //setCurrentDate(event.target.outerText);
  };

  return (
    <div className="discover-week-picker">
      <h3>Week Beginning</h3>
      <Dropdown
        onChange={handleChange}
        options={weekBegining}
        placeholder={props.currentDate}
        selection
        value={props.currentDate}
      />
    </div>
  );
};

export default DatesDropdown;
