import React, { useState, useEffect } from "react";

import { FiChevronLeft, FiImage, FiSettings } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../UI/custom-btn/cstm-btn.component";
import { Menu } from "../UI/Menu";
import { SetItemData } from "../../redux/sets";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchCurrentSetData } from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import { setpreviouspage } from "../../redux/previouspage";
import ScaleLoader from "react-spinners/ScaleLoader";
import classes from "./set.module.css";
//@ts-ignore
import authorization from "../../authorization";
import { Dropdown, Input, Modal, Button } from "semantic-ui-react";
import Parragrapher from "../UI/Paragrapher/Paragraher";
export interface SnafleSet {
  id: string;
  title: string;
  description: string;
  username?: string;
  subscriberCount: number;
  numItems: number;
  status: string;
}

export const SetComp = (props: any) => {
  const subTypes = [
    { key: "FREE", value: "FREE", text: "Free" },
    { key: "PRO", value: "PRO", text: "Pro" },
    { key: "CHARITY", value: "CHARITY", text: "Charity" },
  ];

  const statuses = [
    { key: "PUBLISHED", value: "PUBLISHED", text: "Published" },
    { key: "DRAFT", value: "DRAFT", text: "Draft" },
  ];

  const SFX = () => {
    let values = [];
    for (let i = 1; i <= 10; i++) {
      values.push({ key: i * 100, value: i * 100, text: i * 100 });
    }
    return values;
  };
  const FIAT = () => {
    let values = [];
    for (let i = 1; i <= 10; i++) {
      values.push({ key: i * 5, value: i * 5, text: i * 5 });
    }
    return values;
  };

  const [setData, setSetData] = useState<SnafleSet>();
  const [setAuthor, setSetAuthor] = useState("");
  const [setDesc, setSetDesc] = useState("");
  const [setSubCount, setSetSubCount] = useState(0);
  const [numItems, setNumItems] = useState(0);
  const [setTitle, setSetTitle] = useState("");
  const [setItemData, setSetItemData] = useState<SetItemData[]>([]);
  const [setId, setSetId] = useState("");
  const [status, setStatus] = useState("");
  const [subType, setSubType] = useState("FREE");
  const [subCost, setSubCost] = useState(0);
  const [subSFX, setSubSFX] = useState(0);
  const [ownerId, setOwnerId] = useState("");
  const [open, setOpen] = useState(false);
  const url = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/getSet?id=${props.match.params.id}`;
  const getItems = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/getItemsBySet?id=${props.match.params.id}&webapp=true`;
  const publish = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/updateSet`;

  const snaflerData = {
    key: "269e2220-2529-4fbd-a531-7ce5107f6330",
    value: "269e2220-2529-4fbd-a531-7ce5107f6330",
    text: "Snafler",
  };

  const fetchSetData = async () => {
    return await fetch(url, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setSetAuthor(data.username! as string);
        setSetTitle((data.title! as string).replaceAll("/", "%2F"));
        setNumItems(data.numItems);
        setSetDesc(data.description! as string);
        setSetSubCount(data.subscriberCount);
        setSetId(data.id);
        setStatus(data.status);
        setSubType(data.subscriptionType);
        setSubCost(data.fiatValue);
        setSubSFX(data.SFXValue);
        setOwnerId(data.ownedBy);
      });
  };
  const prevPageCreate = useAppSelector(
    (state) => state.previouspage.previousPageCreateSet
  );

  const fetchItemsBySet = async () => {
    return await fetch(getItems, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setSetItemData(
          data.items.map((item: SetItemData) => ({
            id: item.id,
            title: item.title,
            description: item.description,
          }))
        );
      });
  };

  const updateSet = async (data: {
    SFXValue?: number;
    fiatValue?: number;
    status?: string;
    ownedBy?: string;
    subscriptionType?: string;
  }) => {
    await fetch(publish, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await authorization()),
      },
      body: JSON.stringify({
        id: props.match.params.id,
        ...data,
      }),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  const changeSFX = (event: any, data: any) => {
    setSubSFX(data.value);
    setSubType("PRO");
    updateSet({ SFXValue: data.value, fiatValue: 0, subscriptionType: "PRO" });
  };
  const changeFiat = (event: any, data: any) => {
    setSubCost(data.value);
    setSubType("CHARITY");
    updateSet({
      fiatValue: data.value,
      SFXValue: 0,
      subscriptionType: "CHARITY",
    });
  };
  const changeStatus = (event: any, data: any) => {
    setStatus(data.value);
    updateSet({ status: data.value });
  };

  const confirmChangeHandler = () => {
    updateSet({ ownedBy: snaflerData.key });
    setOpen(false);
    setSetAuthor(snaflerData.text);
    setOwnerId(snaflerData.key);
  };

  const changeSubType = (event: any, data: any) => {
    setSubType(data.value);
    setSubSFX(0);
    setSubCost(0);
    updateSet({ subscriptionType: data.value, SFXValue: 0, fiatValue: 0 });
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSetData();
    dispatch(fetchCurrentSetData(url));
    dispatch(deleteCurrentItem());
    fetchItemsBySet();
  }, [dispatch]);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <div>
      {isLoading ? (
        <div className="loadingScreen">
          <ScaleLoader />
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.match.params.id}.jpg`,
            }}
            className="top-image-container"
          >
            <div className={classes.NavContainer}>
              <FiChevronLeft
                color="white"
                size={24}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  console.log("Back navigation as" + prevPageCreate);
                  if (prevPageCreate) {
                    dispatch(setpreviouspage(false));
                    history.push("/home");
                  } else {
                    dispatch(setpreviouspage(false));
                    history.goBack();
                  }
                }}
              />
              <div className="icons-right">
                <FiImage
                  style={{ cursor: "pointer" }}
                  color="white"
                  size={24}
                  onClick={() => {
                    history.push(`/editSetImage/${props.match.params.id}`);
                  }}
                />
                <FiSettings
                  style={{ cursor: "pointer" }}
                  color="white"
                  size={24}
                  onClick={() => {
                    history.push(`/editSet/${props.match.params.id}`);
                  }}
                />
              </div>
            </div>

            <div className={classes.TopContainer}>
              <h2>{setTitle}</h2>
              <p>Created By: {setAuthor}</p>
              <p>
                {numItems} Items | {setSubCount} Subscribers
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <CustomButton
                buttonTitle="Add Item"
                connection={`/set/${props.match.params.id}/createSnafleItem/${setTitle}`}
              />
            </div>
          </div>
          <div className="item-set-cont">
            <div className="set-desc-cont">
              <h3>Set Info</h3>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>OWNER</p>
                <div style={{ margin: "0 1%" }}>
                  <Input value={setAuthor} disabled={true} />
                  {!(setAuthor === "Snafler") && (
                    <Modal
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      trigger={
                        <Button color="orange">Set Owner Snafler</Button>
                      }
                    >
                      <Modal.Header>Change Owner</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <Modal.Header>
                            This will permanently change the sets owner to
                            Snafler
                          </Modal.Header>
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color="red" onClick={() => setOpen(false)}>
                          Cancel
                        </Button>
                        <Button color="green" onClick={confirmChangeHandler}>
                          Continue
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>STATUS</p>
                <div style={{ margin: "0 1%" }}>
                  <Dropdown
                    selection
                    options={statuses}
                    value={status}
                    onChange={changeStatus}
                    placeholder="Select a status"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>SUBSCRIPTION TYPE</p>
                <div style={{ margin: "0 1%" }}>
                  <Dropdown
                    selection
                    options={subTypes}
                    value={subType}
                    onChange={changeSubType}
                    placeholder="Select a Subscription Type"
                  />
                </div>
              </div>
              {subType === "CHARITY" ? (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "2% 0",
                  }}
                >
                  <p>PRICE GBP</p>
                  <div style={{ margin: "0 1%" }}>
                    <Dropdown
                      selection
                      options={FIAT()}
                      value={subCost}
                      onChange={changeFiat}
                      placeholder="Select a £ Value"
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "2% 0",
                  }}
                >
                  <p>PRICE SFX</p>
                  <div style={{ margin: "0 1%" }}>
                    <Dropdown
                      selection
                      options={SFX()}
                      value={subSFX}
                      onChange={changeSFX}
                      placeholder="Select an SFX Value"
                    />
                  </div>
                </div>
              )}

              <h3>Set Description</h3>
              <Parragrapher text={setDesc} />
            </div>
            {setItemData.length > 0 ? (
              <Menu
                data={setItemData}
                dataPassThrough="item"
                setId={props.match.params.id}
              />
            ) : (
              <div style={{ height: "40vh" }}>
                <div className={classes.EmptyItemsCont}>
                  <Link
                    style={{ color: "var(--primary-color)" }}
                    to={`/set/${props.match.params.id}/createSnafleItem/${setTitle}`}
                  >
                    <h2>Add Some Items</h2>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
