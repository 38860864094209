import { Form, Input, Checkbox } from "semantic-ui-react";

const AddGeoFence = (props: any) => {
  const handleCheckbox = (event: any, data: any) => {
    props.onCheckboxChange(data);
  };

  const onRadiusHandler = (r: any) => {
    let valid = r.target.value;
    props.onRadiusChange(valid);
  };

  return (
    <div>
      <Form.Field>
        <label>Geofencing</label>
        <Checkbox toggle checked={props.geofence} onChange={handleCheckbox} />
        <p style={{ fontSize: "" }}>
          Only allow players to check in if they are within a chosen radius of
          the location
        </p>
      </Form.Field>
      {props.geofence && (
        <Form.Field>
          <label>Set Geofence distance</label>
          <Input
            value={props.georadius}
            onChange={onRadiusHandler}
            label="Radius between 100m and 2000m"
          />
        </Form.Field>
      )}
    </div>
  );
};

export default AddGeoFence;
