import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
//@ts-ignore
import LinesEllipsis from "react-lines-ellipsis";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Accordion, Icon } from "semantic-ui-react";
import { useState } from "react";
import { useAppSelector } from "../../../hooks";

interface ItemSectionDailyProps {
  title: string;
  data: { id: string; title: string }[];
  cap: number;
  isLoading: boolean;
  dailySetCount: number;
  handleOnDragEndDaily: (result: any) => void;
  removefromDaily: (id: string) => void;
}

const ItemSectionDaily = (props: ItemSectionDailyProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const dailySetCount = useAppSelector(
    (state) => state.selection.dailysetcount
  );

  const availableDays = [
    { key: "Monday", text: "Monday", value: "Monday" },
    { key: "Tuesday", text: "Tuesday", value: "Tuesday" },
    { key: "Wednesday", text: "Wednesday", value: "Wednesday" },
    { key: "Thursday", text: "Thursday", value: "Thursday" },
    { key: "Friday", text: "Friday", value: "Friday" },
    { key: "Saturday", text: "Saturday", value: "Saturday" },
    { key: "Sunday", text: "Sunday", value: "Sunday" },
  ];
  const handleAccordionClick = (e: any, propsIndex: any) => {
    const { index } = propsIndex;
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(0);
    }
  };

  return (
    <div>
      {props.isLoading ? (
        <div
          className="item-section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScaleLoader />
        </div>
      ) : (
        <div className="item-section">
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleAccordionClick}
            >
              <div style={{ display: "flex" }}>
                <h3>
                  {props.title} - {dailySetCount}/{props.cap}
                </h3>
                <Icon name="dropdown" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <DragDropContext onDragEnd={props.handleOnDragEndDaily}>
                <Droppable droppableId="drag-n-drop-daily">
                  {(provided) => (
                    <div
                      className="drag-n-drop"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {props.data.map(
                        (item: { id: string; title: string }, index) => (
                          <div key={item.id}>
                            <p className="discover-daily-p">
                              {availableDays[index].value}
                            </p>

                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="list-item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {item.id === "null" || item.title === "" ? (
                                    <LinesEllipsis
                                      className="txt"
                                      text={`Add a set to ${availableDays[index].value}`}
                                      maxLine="1"
                                      basedOn="letters"
                                      trimRight
                                      ellipsis="..."
                                    />
                                  ) : (
                                    <>
                                      <LinesEllipsis
                                        className="txt"
                                        text={item.title}
                                        maxLine="1"
                                        basedOn="letters"
                                        trimRight
                                        ellipsis="..."
                                      />
                                      <div
                                        onClick={() => {
                                          console.log("DELETE");
                                          props.removefromDaily(item.id);
                                        }}
                                        className="delete"
                                        style={{ float: "right" }}
                                      >
                                        <FiTrash2 size={16} />
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </div>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default ItemSectionDaily;
