import GeneralCard from "./GeneralCard";

export const SetPro = (props: {
  item: any;
  proSetsDiscover: { id: string; title: string }[];
  addToProSets: (data: { id: string; title: string }) => void;
}) => {
  return (
    <GeneralCard targetId={props.item.id}>
      <h2>{props.item.title}</h2>
      <div className="button-group">
        <button
          className="button-discover"
          onClick={(e: any) => {
            props.addToProSets({ id: props.item.id, title: props.item.title });
            e.stopPropagation();
          }}
          disabled={
            props.proSetsDiscover.findIndex(
              (v: any) => v.id === props.item.id
            ) >= 0 || props.proSetsDiscover?.length === 20
          }
        >
          Add to Pro Sets
        </button>
      </div>
    </GeneralCard>
  );
};

export default SetPro;
