import styled from "styled-components";

export const Button = styled.button`
  background-color: var(--primary-color);
  border: none;
  outline: none;
  border-radius 20px;
  height:40px
  width: 20rem
  color: white;
  font-size:2rem
  &:hover{
    cursor:pointer;
  }
`;
