interface Set {
  id: string;
  title: string;
}

interface selctionActions {
  CHANGE_DAILY: string;
  CHANGE_WEEKLY: string;
  CHANGE_TIMED: string;
  CHANGE_PIXEL: string;
  CHANGE_PRO: string;
  CHANGE_TRENDING: string;
  CHANGE_AMBASSADORS: string;
  CHANGE_NEW: string;
  CHANGE_COUNTER: string;
}

const INITIAL_STATE = {
  daily: [],
  timed: [],
  pixel: [],
  weekly: [],
  pro: [],
  trending: [],
  ambassadors: [],
  newSets: [],
  dailysetcount: 0,
};

const selctionActionsType: selctionActions = {
  CHANGE_DAILY: "CHANGE_DAILY",
  CHANGE_WEEKLY: "CHANGE_WEEKLY",
  CHANGE_TIMED: "CHANGE_TIMED",
  CHANGE_PIXEL: "CHANGE_PIXEL",
  CHANGE_PRO: "CHANGE_PRO",
  CHANGE_TRENDING: "CHANGE_TRENDING",
  CHANGE_AMBASSADORS: "CHANGE_AMBASSADORS",
  CHANGE_NEW: "CHANGE_NEW",
  CHANGE_COUNTER: "CHANGE_COUNTER",
};

export const changeDaily = (daily: Set[]) => ({
  type: selctionActionsType.CHANGE_DAILY,
  payload: daily,
});

export const changeWeekly = (weekly: Set[]) => ({
  type: selctionActionsType.CHANGE_WEEKLY,
  payload: weekly,
});

export const changeTimed = (timed: Set[]) => ({
  type: selctionActionsType.CHANGE_TIMED,
  payload: timed,
});

export const changePixel = (pixel: Set[]) => ({
  type: selctionActionsType.CHANGE_PIXEL,
  payload: pixel,
});

export const changePro = (pro: Set[]) => ({
  type: selctionActionsType.CHANGE_PRO,
  payload: pro,
});
export const changeTrending = (trending: Set[]) => ({
  type: selctionActionsType.CHANGE_TRENDING,
  payload: trending,
});
export const changeAmbassadors = (ambassadors: Set[]) => ({
  type: selctionActionsType.CHANGE_AMBASSADORS,
  payload: ambassadors,
});
export const changeNew = (newSets: Set[]) => ({
  type: selctionActionsType.CHANGE_NEW,
  payload: newSets,
});

export const changeCounter = (counter: number) => ({
  type: selctionActionsType.CHANGE_COUNTER,
  payload: counter,
});

const selectionReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case selctionActionsType.CHANGE_DAILY:
      return { ...state, daily: action.payload };
    case selctionActionsType.CHANGE_WEEKLY:
      return { ...state, weekly: action.payload };
    case selctionActionsType.CHANGE_TIMED:
      return { ...state, timed: action.payload };
    case selctionActionsType.CHANGE_PIXEL:
      return { ...state, pixel: action.payload };
    case selctionActionsType.CHANGE_PRO:
      return { ...state, pro: action.payload };
    case selctionActionsType.CHANGE_TRENDING:
      return { ...state, trending: action.payload };
    case selctionActionsType.CHANGE_AMBASSADORS:
      return { ...state, ambassadors: action.payload };
    case selctionActionsType.CHANGE_NEW:
      return { ...state, newSets: action.payload };
    case selctionActionsType.CHANGE_COUNTER:
      return { ...state, dailysetcount: action.payload };
    default:
      return state;
  }
};

export default selectionReducer;
