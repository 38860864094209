import { SwitchContainer } from "./navbar.styles";
import MaintenanceModal from "./maintainaceModal.component";

const MaintenanceToggle = () => {
  return (
    <SwitchContainer>
      Maintenance Mode
      <MaintenanceModal />
    </SwitchContainer>
  );
};

export default MaintenanceToggle;
