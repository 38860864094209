import { Grid, Input } from "semantic-ui-react";
import classes from "./Filterbar.module.css";
import IndividalFilter from "../../UI/ReusedFilters/individualFilter";
import ActivitiesFilter from "../../UI/ReusedFilters/ActivitiesFilter";
import CategoriesFilter from "../../UI/ReusedFilters/CategoriesFilter";

interface FilterBarProps {
  searchSetHandler: (value: any) => void;
  onUserChange: (value: any) => void;
  currentUser: boolean;
  onCategoryChange: (value: any) => void;
  currentCategory: string;
  onActivityChange: (value: any) => void;
  currentActivity: string;
  onStatusChange: (value: any) => void;
  currentStatus: string;
  onProChange: (value: any) => void;
  currentPro: string;
  currentTitleSearch: string;
  onTitleSearchChange: (value: any) => void;
  resetFilters: () => void;
}

const FilterBar = (props: FilterBarProps) => {
  const userOptions = [
    { key: "YOU", value: false, text: "Current User" },
    { key: "SNAFLER", value: true, text: "All Users" },
  ];

  const proOptions = [
    { key: "PRO", value: "PRO", text: "Pro Sets" },
    { key: "FREE", value: "FREE", text: "Regular Sets" },
    { key: "ALL", value: "ALL", text: "All Sets" },
    { key: "CHARITY", value: "CHARITY", text: "Charity Sets" },
  ];

  const statusOptions = [
    { key: "PUBLISHED", value: "PUBLISHED", text: "Published" },
    { key: "DRAFT", value: undefined, text: "Draft" },
    { key: "ANY", value: "ANY", text: "All Sets" },
  ];

  const SeachChangeHandler = (item: any) => {
    console.log(item.target.value);
    props.onTitleSearchChange(item.target.value);
  };

  return (
    <div className={classes.filterBar}>
      <div className={classes.Header}>
        <h1>Filter Sets By</h1>
        <button className={classes.ResetButton} onClick={props.resetFilters}>
          Reset Filters
        </button>
      </div>

      <Grid columns={6}>
        <Grid.Row>
          <IndividalFilter
            title="Owner"
            placeholder="Select Account"
            options={userOptions}
            onChange={props.onUserChange}
            currentValue={props.currentUser}
          />
          <CategoriesFilter
            currentCategory={props.currentCategory}
            onCategoryChange={props.onCategoryChange}
          />
          <ActivitiesFilter
            onActivityChange={props.onActivityChange}
            currentActivity={props.currentActivity}
          />
          <IndividalFilter
            title="Status"
            options={statusOptions}
            placeholder="Filter Buy Status"
            onChange={props.onStatusChange}
            currentValue={props.currentStatus}
          />
          <IndividalFilter
            title="Set Type"
            options={proOptions}
            placeholder="Filter Set Type"
            onChange={props.onProChange}
            currentValue={props.currentPro}
          />
          <Grid.Column>
            <h4>Search Titles</h4>
            <Input
              placeholder="Set Titles"
              value={props.currentTitleSearch}
              onChange={SeachChangeHandler}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default FilterBar;
