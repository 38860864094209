interface feedbackPreviousActions {
  SET_PREVIOUS_FEEDBACK: string;
  INVALID_TAB_NO: string;
}

const feedbackPreviousActionsType: feedbackPreviousActions = {
  SET_PREVIOUS_FEEDBACK: "SET_PREVIOUS_FEEDBACK",
  INVALID_TAB_NO: "INVALID_TAB_NO",
};

const INITIAL_STATE = {
  previousFeedbackTab: 0,
};

export const setPreviousTab = (data: number) => {
  if (data >= 0 && data <= 1) {
    return {
      type: feedbackPreviousActionsType.SET_PREVIOUS_FEEDBACK,
      payload: data,
    };
  } else {
    return {
      type: feedbackPreviousActionsType.INVALID_TAB_NO,
      payload: 0,
    };
  }
};

const feedbackPreviousReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case feedbackPreviousActionsType.SET_PREVIOUS_FEEDBACK:
      return { previousFeedbackTab: action.payload };
    case feedbackPreviousActionsType.INVALID_TAB_NO:
      return state;
    default:
      return state;
  }
};

export default feedbackPreviousReducer;
