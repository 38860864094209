import Moderation from "../components/Moderation/moderation.component";
import PageShell from "../components/UI/PageShell/PageShell";

export const ContentModerationScreen = () => {
  return (
    <PageShell title="Content Moderation">
      <Moderation />
    </PageShell>
  );
};

export default ContentModerationScreen;
