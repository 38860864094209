import { Form } from "semantic-ui-react";

const AddTitle = (props: any) => {
  const onTitleChangeHandler = (v: any) => {
    let value = v.target.value;
    props.onTitleChange(value);
  };

  return (
    <Form.Field required>
      <label>Title</label>
      <input
        required={true}
        type="text"
        id="ItemTitle"
        name="ItemTitle"
        placeholder="What is the title of your item?"
        value={props.itemTitle}
        onChange={onTitleChangeHandler}
      />
    </Form.Field>
  );
};

export default AddTitle;
