import classes from "./NoSetsPage.module.css";

const NoAmbassadors = () => {
  return (
    <div>
      <div className={classes.MessaegContainer}>
        <h1 className={classes.TextContent}>No Matching Usernames</h1>
      </div>
    </div>
  );
};

export default NoAmbassadors;
