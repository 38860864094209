import IndividalFilter from "../../UI/ReusedFilters/individualFilter";
import { useAppSelector } from "../../../hooks";

interface CategoriesFilterProps {
  onCategoryChange: (value: any) => void;
  currentCategory: string;
}

const CategoriesFilter = (props: CategoriesFilterProps) => {
  const categoriesRaw = useAppSelector((state) => state.category).data;
  const categoriesOptions = categoriesRaw.map((cat: any) => {
    return { key: cat.id, value: cat.id, text: cat.name };
  });
  categoriesOptions.push({ key: "0", value: "0", text: "All Categories" });

  return (
    <IndividalFilter
      title="Category"
      options={categoriesOptions}
      placeholder="Choose Category"
      onChange={props.onCategoryChange}
      currentValue={props.currentCategory}
    />
  );
};

export default CategoriesFilter;
