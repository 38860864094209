import { useHistory } from "react-router-dom";

const GeneralCard = (props: any) => {
  const history = useHistory();

  const showSetDetails = () => {
    history.push(`/set/${props.targetId}`);
  };

  return (
    <div
      className="set-card"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.targetId}.jpg`,
      }}
      onClick={showSetDetails}
    >
      {props.children}
    </div>
  );
};

export default GeneralCard;
