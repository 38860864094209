import React, { useEffect, useState } from "react";
import { UploadLevel } from "../../uploadToS3";
import { NavBarTopCustom } from "../UI/Navbar";
import { Storage } from "aws-amplify";
import authorization from "../../authorization";
import DetailsSummary from "./ConditionalContent/detailsSummary";
import ModeratorComments from "./ConditionalContent/ModeratorComment";
import ButtonHolder from "./buttons/buttonHolder";
import { RightModeration, RightVerification } from "./rightHandside";
import RightContent from "./rightHandside/RightContent.component";

const ViewReport = (props: any) => {
  const [reportId, setReportId] = useState(props.match.params.reportId);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [currentReport, setCurrentReport] = useState<any>();
  const [modComments, setModComments] = useState("");
  const [dropDown, setDropDown] = useState("");
  const [currentReportType, setCurrentType] = useState("");
  const [reportContent, setReportContent] = useState<any>();

  type MediaType =
    | "post"
    | "profile_cover"
    | "profile_image"
    | "profile_thumbnail"
    | "report_problem"
    | "feedback"
    | "verification";
  const filepathHandler = (
    idPlusMetadata: string,

    type: MediaType
  ): string => {
    return `${idPlusMetadata}_${type}`;
  };

  const getReportData = async (userSub: string, sourceId: string) => {
    console.log(userSub);

    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationReport?userSub=${userSub}&sourceId=${sourceId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        setCurrentReport(res);
        return res;
      });
    await getPresignedUrl(data?.cognitoid, data?.sourceId, data?.sourceType);
  };

  const getReport = async (userSub: string, sourceId: string) => {
    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getReport?userSub=${userSub}&sourceId=${sourceId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCurrentReport(res);
        return res;
      });
    const url = "https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev";
    if (data.sourceType === "SET") {
      await fetch(`${url}/getSet?id=${data.sourceId}`, {
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "ITEM") {
      await fetch(`${url}/getSetItem?id=${data.sourceId}`, {
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "POST") {
      await fetch(`${url}/getPost?postId=${data.sourceId}`, {
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "COMMENT") {
    }
    console.log(reportContent);
  };

  const getCCPR = async (userSub: string, requestId: string) => {
    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPR?userSub=${userSub}&requestId=${requestId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCurrentReport(res);
        return res;
      });
    await getPresignedUrl(
      data?.cognitoId,
      filepathHandler(data?.userSub, "verification"),
      "set"
    );
  };

  const getPresignedUrl = async (
    identityId: string,
    assetName: string,
    type:
      | "post"
      | "set"
      | "item"
      | "profile_thumbnail"
      | "profile_cover"
      | "profile_image"
  ) => {
    console.log({ identityId, assetName });

    return await Storage.get(
      `${assetName}${
        type === "post"
          ? "_IMAGE_1"
          : type === "profile_thumbnail" ||
            type === "profile_cover" ||
            type == "profile_image"
          ? `_${type}`
          : ""
      }.jpg`,
      {
        identityId,
        level: UploadLevel.PROTECTED,
      }
    )
      .then((data: any) => {
        if (typeof data === "string") {
          setImageUrl(data);
        } else {
          console.log("Did not return a string: " + data);
        }
      })
      .catch((err: any) => console.log(err));
  };

  const dropDownValue = (value: any) => {
    setDropDown(value);
  };

  const modCommentsHandler = (value: any) => {
    setModComments(value);
  };

  useEffect(() => {
    const ids = reportId.split("_");
    if (ids[0] === "MOD") {
      getReportData(`${ids[1]}_${ids[2]}`, ids[3]);
    } else if (ids[0] === "REP") {
      getReport(ids[1], ids[2]);
    } else if (ids[0] === "VER") {
      getCCPR(ids[1], ids[2]);
    }
    setCurrentType(ids[0]);
  }, []);

  return (
    <>
      <NavBarTopCustom title="Report" />
      <div className="mod-report-container">
        <div className="mod-main-flex">
          <div className="mod-left">
            <DetailsSummary
              currentReport={currentReport}
              currentReportType={currentReportType}
              imageUrl={imageUrl}
            />
            <ModeratorComments
              currentReportType={currentReportType}
              onDropDownChange={dropDownValue}
              onTextAreaChange={modCommentsHandler}
            />
            <ButtonHolder
              currentReport={currentReport}
              currentReportType={currentReportType}
              dropDown={dropDown}
              modComments={modComments}
            />
          </div>
          <div className="mod-right">
            <div className={"mod-image-container"}>
              {currentReportType === "MOD" && (
                <RightModeration imageUrl={imageUrl} />
              )}
              {currentReportType === "VER" && (
                <RightVerification
                  currentReport={currentReport}
                  imageUrl={imageUrl}
                />
              )}
              {currentReportType === "REP" && (
                <RightContent
                  currentReport={currentReport}
                  reportContent={reportContent}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewReport;
