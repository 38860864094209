interface RightContentProps {
  currentReport: any;
  reportContent: any;
}

export const RightContent = (props: RightContentProps) => {
  return (
    <div>
      <h2>Content</h2>
      {props.currentReport?.sourceType === "ITEM" ||
      props.currentReport?.sourceType === "SET" ? (
        <div className={"mod-item"}>
          <h2>{props.reportContent?.title}</h2>
          <h3>Description</h3>
          <p>{props.reportContent?.description}</p>
          <h3>Created By</h3>
          <p>
            {props.reportContent?.createdBy ===
            "269e2220-2529-4fbd-a531-7ce5107f6330"
              ? "Snafler"
              : props.reportContent?.createdBy}
          </p>
          {props.reportContent?.createdTimestamp ? (
            <>
              <h3>Created on</h3>
              <p>
                {new Date(props.reportContent?.createdTimestamp).toLocaleString(
                  "en-GB"
                )}
              </p>
            </>
          ) : (
            <></>
          )}
          <h3>Tags</h3>
          <p>{props.reportContent?.tags?.map((x: string) => `#${x},`)}</p>
          <div>
            <h3>Location</h3>
            <p>
              {props.reportContent?.location?.name}, <br />
              {props.reportContent?.location?.formatted_address}, <br />
              {props.reportContent?.location?.type}, <br />
              {props.reportContent?.location?.country_code}
              <br />
            </p>
          </div>
          <h3>Image</h3>
          <a
            href={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.reportContent?.id}.jpg`}
            download
            target="_blank"
          >
            <img
              src={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.reportContent?.id}.jpg`}
              className="mod-content-image"
              alt="Moderation"
            />
          </a>
        </div>
      ) : (
        <></>
      )}
      {props.currentReport?.sourceType === "POST" ? (
        props.reportContent?.postType === undefined ||
        props.reportContent?.postType === null ? (
          <>
            <h1>No content</h1>
          </>
        ) : (
          <div className={"mod-item"}>
            {props.reportContent?.postType === "VIDEO" ? (
              <video className="mod-content-image" controls autoPlay>
                <source
                  src={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.reportContent?.id}_VIDEO_1.mp4`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <></>
            )}
            {props.reportContent?.postType === "IMAGE" ? (
              <div>
                <a
                  href={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.reportContent?.id}_IMAGE_1.jpg`}
                  download
                  target="_blank"
                >
                  <img
                    src={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.reportContent?.id}_IMAGE_1.jpg`}
                    className="mod-content-image"
                  />
                </a>
              </div>
            ) : (
              <></>
            )}
            <h3>Post Caption</h3>
            <p>{props.reportContent?.description}</p>
            <h3>Post Stats</h3>
            <p>
              Like Count: {props.reportContent?.likeCount},<br />
              Comment Count: {props.reportContent?.commentCount}
            </p>
            <h3>Date Created</h3>
            <p>{new Date(props.reportContent?.date).toLocaleString("en-GB")}</p>
            <h3>Post Status</h3>
            <p>{props.reportContent?.status}</p>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
export default RightContent;
