import { FiTrash2 } from "react-icons/fi";
//@ts-ignore
import LinesEllipsis from "react-lines-ellipsis";
import ScaleLoader from "react-spinners/ScaleLoader";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Accordion, Icon } from "semantic-ui-react";
import { useState } from "react";

const ItemSection = (props: {
  title: string;
  data: { id: string; title: string }[];
  cap: number;
  deleteMethod: (id: string) => void;
  dragMethod: (result: any) => void;
  isLoading: boolean;
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (e: any, propsIndex: any) => {
    const { index } = propsIndex;
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(0);
    }
  };

  return (
    <div>
      {props.isLoading ? (
        <div
          className="item-section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScaleLoader />
        </div>
      ) : (
        <div className="item-section">
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleAccordionClick}
            >
              <div style={{ display: "flex" }}>
                <h3>
                  {props.title} - {props.data.length}/{props.cap}
                </h3>
                <Icon name="dropdown" size="massive" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <DragDropContext onDragEnd={props.dragMethod}>
                <Droppable droppableId="drag-n-drop-weekly">
                  {(provided) => (
                    <div
                      className="drag-n-drop"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {props.data.map(
                        (item: { id: string; title: string }, index) => (
                          <div key={item.id} style={{ padding: "1%  0 " }}>
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="list-item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <LinesEllipsis
                                    className="txt"
                                    text={item.title}
                                    maxLine="1"
                                    basedOn="letters"
                                    trimRight
                                    ellipsis="..."
                                  />
                                  <div
                                    onClick={() => {
                                      console.log("DELETE");
                                      props.deleteMethod(item.id);
                                    }}
                                    className="delete"
                                    style={{ float: "right" }}
                                  >
                                    <FiTrash2 size={16} />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Accordion.Content>
          </Accordion>
        </div>
      )}
    </div>
  );
};
export default ItemSection;
