import { Auth } from "aws-amplify";

const LogoutButton = () => {
  return (
    <button
      style={{
        border: "none",
        backgroundColor: "white",
        outline: "none",
        fontSize: "1.2em",
        cursor: "pointer",
      }}
      onClick={() => {
        Auth.signOut();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }}
    >
      Logout
    </button>
  );
};

export default LogoutButton;
