import { AddActComp } from "../components/AddActivities/addActivities.component";
import PageShell from "../components/UI/PageShell/PageShell";

export const ActivitiesScreen = () => {
  return (
    <PageShell title="Manage Activities">
      <AddActComp />
    </PageShell>
  );
};

export default ActivitiesScreen;
