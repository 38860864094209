import ContentCreator from "../components/ContentCreators/contentCreators";
import PageShell from "../components/UI/PageShell/PageShell";

export const ContentCreatorsScreen = () => {
  return (
    <PageShell title="Content Creators">
      <ContentCreator />
    </PageShell>
  );
};

export default ContentCreatorsScreen;
