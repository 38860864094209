import SocialMediaTable from "../ConditionalContent/SocialMediaTable";

interface RightVerificationProps {
  currentReport: any;
  imageUrl: string;
}

export const RightVerification = (props: RightVerificationProps) => {
  return (
    <>
      <h2>Document Links</h2>
      <a href={props.imageUrl} download className="mod-files">
        {props.currentReport?.documentType?.toUpperCase()}
      </a>
      <SocialMediaTable currentReport={props.currentReport} />
    </>
  );
};

export default RightVerification;
