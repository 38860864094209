import { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import authorization from "../../authorization";

//New Stuff
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  changeAmbassadors,
  changeCounter,
  changeDaily,
  changeNew,
  changePixel,
  changePro,
  changeTimed,
  changeTrending,
  changeWeekly,
} from "../../redux/selection";
import { SetPro, AmbassadorCard, SetPixel, SetTimed, Set } from "./Cards";
import { setPreviousTabD } from "../../redux/previousDiscover";
import RightSelection from "./Columns/RightSelection";
import DiscFilterBar from "./DiscFilterBar/DiscFilterBar";
import AmbassadorFilterBar from "./DiscFilterBar/AmbassadorFilterBar";
import NoSetsPage from "./DiscFilterBar/NoSetsPage";
import NoAmbassadors from "./DiscFilterBar/NoAmbassadors";

const getWeekBegining = (date: number): Date => {
  let d = new Date(date);
  let day = d.getDay();
  let diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

export const Discover = () => {
  const dispatch = useAppDispatch();

  const dailySets = useAppSelector((state) => state.selection.daily);
  const timedSets = useAppSelector((state) => state.selection.timed);
  const pixelSets = useAppSelector((state) => state.selection.pixel);
  const weeklySets = useAppSelector((state) => state.selection.weekly);
  const proSetsDiscover = useAppSelector((state) => state.selection.pro);
  const trendingSets = useAppSelector((state) => state.selection.trending);
  const ambassadors = useAppSelector((state) => state.selection.ambassadors);
  const newSets = useAppSelector((state) => state.selection.newSets);

  const dailySetCount = useAppSelector(
    (state) => state.selection.dailysetcount
  );

  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();
  const [set, setData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [proSets, setProSets] = useState<any>([]);
  const [pixSets, setPixSets] = useState<any>([]);
  const [tSets, setTSets] = useState<any>([]);
  const [advSearch, setAdvSearch] = useState(false);
  const [displaySets, setDisplaySets] = useState([]);
  const [displayPro, setDisplayPro] = useState([]);
  const [displayTimed, setDisplayTimed] = useState([]);
  const [displayPixel, setDisplayPixel] = useState([]);
  const [displayAmbas, setDisplayAmbas] = useState([]);
  const [setQuery, setSetQuery] = useState(
    `${process.env.REACT_APP_AWS_ENDPOINT2}getSets?`
  );
  const [currentCategory, setCurrentCategory] = useState("0");
  const [currentActivities, setCurrentActivities] = useState("AllActivities");
  const [currentSearch, setCurrentSearch] = useState("");
  const [ambassadorSearch, setAmbassadorSearch] = useState("");
  const [isArchive, setIsArchive] = useState(false);

  const startDate = getWeekBegining(now.getTime()).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const filterSets = (sets: any) => {
    let filterTemp = sets;

    if (!(currentActivities === "" || currentActivities === "AllActivities")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.activityType === currentActivities
      );
    }
    if (!(currentCategory === "" || currentCategory === "0")) {
      filterTemp = filterTemp.filter(
        (set: any) => set.categoryId === currentCategory
      );
    }

    if (!(currentSearch === "")) {
      filterTemp = filterTemp.filter((set: any) =>
        set.title.toLowerCase().includes(currentSearch.toLowerCase())
      );
    }

    console.log(filterTemp);
    return filterTemp;
  };

  const onChangeActivity = (a: string) => {
    setCurrentActivities(a);
  };

  const onChangeCategory = (c: string) => {
    setCurrentCategory(c);
  };

  const onChangeSearch = (s: string) => {
    setCurrentSearch(s);
  };

  const onChangeAmbassador = (s: string) => {
    setAmbassadorSearch(s);
  };

  useEffect(() => {
    setDisplaySets(filterSets(set));
    setDisplayPro(filterSets(proSets));
    setDisplayTimed(filterSets(tSets));
    setDisplayPixel(filterSets(pixSets));
  }, [currentActivities, currentCategory, set, currentSearch]);

  useEffect(() => {
    let filterTemp = users;
    if (!(ambassadorSearch === "")) {
      filterTemp = filterTemp.filter((set: any) =>
        set.username.includes(ambassadorSearch)
      );
    }
    setDisplayAmbas(filterTemp);
  }, [ambassadorSearch, users]);

  const searchHandler = (target: string) => {
    if (target.trim() === "") {
      setAdvSearch(false);
      setSetQuery(`${process.env.REACT_APP_AWS_ENDPOINT2}getSets?`);
    } else {
      setAdvSearch(true);
      setSetQuery(
        `https://api-qa.snafler.com/discover/setSearch?query=${target}&size=20&start=0`
      );
    }
  };

  const previousTab = useAppSelector(
    (state) => state.discoverPrevious.previousDiscoverTab
  );

  const TabChangeHandler = (e: any, data: any) => {
    dispatch(setPreviousTabD(data.activeIndex));
  };

  const getSets = async () => {
    // const user = "269e2220-2529-4fbd-a531-7ce5107f6330";
    console.log("get sets");

    await fetch(setQuery, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        console.log("Search");
        console.log(jsonData);

        if (!advSearch) {
          setData(
            jsonData.sets.map((data: any) => ({
              id: data.id,
              title: data.title,
              description: data.description,
              numItems: data.numItems,
              activityType: data.activityType,
              categoryId: data.categoryId,
            }))
          );
          setTSets(
            jsonData.sets
              .filter((x: any) => x.timedSet)
              .map((data: any) => ({
                id: data.id,
                title: data.title,
                description: data.description,
                numItems: data.numItems,
                timedSet: data.timedSet,
                activityType: data.activityType,
                categoryId: data.categoryId,
              }))
          );
        } else {
          setData(
            jsonData.results.map((data: any) => ({
              id: data.id,
              title: data.title,
              description: data.description,
              numItems: data.numItems,
            }))
          );
          setTSets(
            jsonData.results
              .filter((x: any) => x.timedSet)
              .map((data: any) => ({
                id: data.id,
                title: data.title,
                description: data.description,
                numItems: data.numItems,
                timedSet: data.timedSet,
              }))
          );
        }
      });
  };

  const getProSets = async () => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}getSets?type=PRO`, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setProSets(
          res.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
            subscriptionType: data.subscriptionType,
            activityType: data.activityType,
            categoryId: data.categoryId,
          }))
        );
      });
  };

  const getPixelSets = async () => {
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getSets?action=PIXEL_CANVAS`,
      {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });

        setPixSets(
          res.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
            subscriptionType: data.subscriptionType,
            activityType: data.activityType,
            categoryId: data.categoryId,
          }))
        );
      });
  };

  const getUsers = async () => {
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getAllUsers?webapp=true`,
      {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        console.log("users");
        console.log(data.users);
        setUsers(data.users);
      });
  };

  const [currentDate, setCurrentDate] = useState(
    getWeekBegining(now.getTime()).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );

  const [currentId, setCurrentId] = useState<any>();

  useEffect(() => {
    getSets();
    getUsers();
    getProSets();
    getPixelSets();
  }, []);

  const getDiscoverDataByDate = async (date: string) => {
    date = date.slice(0, 10);
    console.log(date);

    setIsLoading(true);
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getDiscoverData?date=${date}`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => {
        return data?.json();
      })
      .then((data) => {
        console.log(data);
        setCurrentId(data?.id);
        let topDaily = [];
        for (let i in data.topDaily) {
          topDaily.push({
            id: data.topDaily[i]?.id,
            title: data.topDaily[i]?.title,
          });
        }
        dispatch(changeDaily(topDaily));
        dispatch(changeCounter(topDaily.length));
        let topWeekly = [];
        for (let i in data.topWeekly) {
          topWeekly.push({
            id: data.topWeekly[i]?.id,
            title: data.topWeekly[i]?.title,
          });
        }
        dispatch(changeWeekly(topWeekly));
        let topTrending = [];
        for (let i in data.topTrending) {
          topTrending.push({
            id: data.topTrending[i]?.id,
            title: data.topTrending[i]?.title,
          });
        }
        dispatch(changeTrending(topTrending));
        let topNewSets = [];
        for (let i in data.topNewSets) {
          topNewSets.push({
            id: data.topNewSets[i]?.id,
            title: data.topNewSets[i]?.title,
          });
        }
        dispatch(changeNew(topNewSets));
        let ams = [];
        for (let i in data.ambassadors) {
          ams.push({
            id: data.ambassadors[i]?.id,
            title: data.ambassadors[i]?.username,
          });
        }
        let ts = [];
        for (let i in data?.topTimedSets) {
          ts.push({
            id: data?.topTimedSets[i]?.id,
            title: data?.topTimedSets[i]?.title,
          });
        }
        dispatch(changeTimed(ts));
        dispatch(changeAmbassadors(ams));
        let pS = [];
        for (let i in data.proSets) {
          pS.push({
            id: data.proSets[i]?.id,
            title: data.proSets[i]?.title,
          });
        }
        dispatch(changePro(pS));
        let pixS = [];
        for (let i in data.pixelSets) {
          pixS.push({
            id: data.pixelSets[i]?.id,
            title: data.pixelSets[i]?.title,
          });
        }
        dispatch(changePixel(pixS));
        setIsLoading(false);
      })
      .catch((err) => {
        setCurrentId(undefined);
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getDiscoverDataByDate(
      new Date(Date.parse(currentDate)).toLocaleDateString("en-GB")
    );

    if (
      Date.parse(currentDate) + 1 * 24 * 60 * 60 * 1000 <
      getWeekBegining(now.getTime()).getTime()
    ) {
      setIsArchive(true);
    } else {
      setIsArchive(false);
    }
  }, [currentDate]);

  useEffect(() => {
    getSets();
  }, [advSearch, setQuery]);

  const addToDaily = (data: { id: string; title: string }) => {
    if (dailySets.findIndex((v: any) => v.id === data.id) === -1) {
      let index = dailySets.findIndex(
        (v: any) =>
          v.id === "n0" ||
          v.id === "n1" ||
          v.id === "n2" ||
          v.id === "n3" ||
          v.id === "n4" ||
          v.id === "n5" ||
          v.id === "n6"
      );
      if (index !== -1) {
        let arr = dailySets;
        arr[index] = data;
        dispatch(changeDaily([...arr]));
      } else {
        dispatch(changeDaily([...dailySets, data]));
      }

      dispatch(changeCounter(dailySetCount + 1));
    }
  };

  const addToWeekly = (data: { id: string; title: string }) => {
    if (weeklySets.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changeWeekly([...weeklySets, data]));
  };

  const addToTrending = (data: { id: string; title: string }) => {
    if (trendingSets.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changeTrending([...trendingSets, data]));
  };

  const addToNewSets = (data: { id: string; title: string }) => {
    if (newSets.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changeNew([...newSets, data]));
  };

  const addToTimedSets = (data: { id: string; title: string }) => {
    if (timedSets.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changeTimed([...timedSets, data]));
  };

  const addToAmbassadors = (data: { id: string; title: string }) => {
    if (ambassadors.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changeAmbassadors([...ambassadors, data]));
  };

  const addToProSets = (data: { id: string; title: string }) => {
    if (proSetsDiscover.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changePro([...proSetsDiscover, data]));
  };

  const addToPixelSets = (data: { id: string; title: string }) => {
    if (pixelSets.findIndex((v: any) => v.id === data.id) === -1)
      dispatch(changePixel([...pixelSets, data]));
  };

  const handleChangeCurrentDate = (date: string) => {
    setCurrentDate(date);
  };

  const panes = [
    {
      menuItem: `Sets`,
      render: () => <DiscoverSets />,
    },
    {
      menuItem: `Pro Sets`,
      render: () => <ProSets />,
    },
    {
      menuItem: `Timed Sets`,
      render: () => <TimedSets />,
    },

    {
      menuItem: `Pixel Sets`,
      render: () => <PixelSets />,
    },
    {
      menuItem: `Ambassadors`,
      render: () => <DiscoverAmbassadors />,
    },
  ];

  const DiscoverAmbassadors = () => (
    <Tab.Pane>
      <div className="sets-container">
        <AmbassadorFilterBar
          externalSearch={ambassadorSearch}
          changeSearch={onChangeAmbassador}
          key="AmbassBar"
        />
        {displayAmbas.map((item: any) => (
          <AmbassadorCard
            item={item}
            ambassadors={ambassadors}
            addToAmbassadors={addToAmbassadors}
          />
        ))}
      </div>
      {displayAmbas.length === 0 && <NoAmbassadors />}
    </Tab.Pane>
  );

  const ProSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        <DiscFilterBar
          currentActivity={currentActivities}
          currentCategory={currentCategory}
          onActivityChange={onChangeActivity}
          onCategoryChange={onChangeCategory}
          onSearchChange={onChangeSearch}
          currentSearch={currentSearch}
        />
        {displayPro.map((item: any) => (
          <SetPro
            key={item.id}
            item={item}
            proSetsDiscover={proSetsDiscover}
            addToProSets={addToProSets}
          />
        ))}
      </div>
      {displayPro.length === 0 && <NoSetsPage />}
    </Tab.Pane>
  );

  const PixelSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        <DiscFilterBar
          currentActivity={currentActivities}
          currentCategory={currentCategory}
          onActivityChange={onChangeActivity}
          onCategoryChange={onChangeCategory}
          onSearchChange={onChangeSearch}
          currentSearch={currentSearch}
        />
        {displayPixel.map((item: any) => (
          <SetPixel
            key={item.id}
            item={item}
            pixelSets={pixelSets}
            addToPixelSets={addToPixelSets}
          />
        ))}
      </div>
      {displayPixel.length === 0 && <NoSetsPage />}
    </Tab.Pane>
  );

  const TimedSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        <DiscFilterBar
          currentActivity={currentActivities}
          currentCategory={currentCategory}
          onActivityChange={onChangeActivity}
          onCategoryChange={onChangeCategory}
          onSearchChange={onChangeSearch}
          currentSearch={currentSearch}
        />
        {displayTimed.map((item: any) => (
          <SetTimed
            key={item.id}
            item={item}
            timedSets={timedSets}
            addToTimedSets={addToTimedSets}
          />
        ))}
      </div>
      {displayTimed.length === 0 && <NoSetsPage />}
    </Tab.Pane>
  );

  const DiscoverSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        <DiscFilterBar
          currentActivity={currentActivities}
          currentCategory={currentCategory}
          onActivityChange={onChangeActivity}
          onCategoryChange={onChangeCategory}
          onSearchChange={onChangeSearch}
          currentSearch={currentSearch}
        />
        {displaySets.map((item: any) => (
          <Set
            key={item.id}
            item={item}
            dailySets={dailySets}
            dailySetCount={dailySetCount}
            weeklySets={weeklySets}
            newSets={newSets}
            trendingSets={trendingSets}
            addToDaily={addToDaily}
            addToWeekly={addToWeekly}
            addToTrending={addToTrending}
            addToNewSets={addToNewSets}
          />
        ))}
      </div>
      {displaySets.length === 0 && <NoSetsPage />}
    </Tab.Pane>
  );

  return (
    <div className="primary-container">
      <div className="left-column-free">
        <Tab
          panes={panes}
          renderActiveOnly={true}
          defaultActiveIndex={previousTab}
          onTabChange={TabChangeHandler}
        />
      </div>
      <div className="right-column-fixed">
        <RightSelection
          ChangeCurrentDate={handleChangeCurrentDate}
          currentDate={currentDate}
          currentId={currentId}
          getDiscoverDataByDate={getDiscoverDataByDate}
          isLoading={isLoading}
          isArchive={isArchive}
          startDate={startDate}
        />
      </div>
    </div>
  );
};
