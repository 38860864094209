import React, { useState } from "react";
import { SetData, SetItemData } from "../../../redux/sets";
import {
  MenuContainer,
  MenuImage,
  MenuItem,
  MenuDescription,
} from "./menu.styles";
import { MenuItemComp } from "./menuItem.component";
import ScaleLoader from "react-spinners/ScaleLoader";
export const Menu = (props: {
  data: SetData[] | SetItemData[];
  setId: string;
  dataPassThrough: string;
}) => {
  console.log(props.data);
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  return (
    <>
      {isLoading ? (
        <div className="loadingScreen">
          <ScaleLoader />
        </div>
      ) : (
        <>
          <MenuContainer>
            {props.data.map((item) => (
              <MenuItemComp
                key={item.id}
                setId={props.setId}
                id={item.id}
                title={item.title}
                description={item.description}
                dataPassThrough={props.dataPassThrough}
              />
            ))}
          </MenuContainer>
        </>
      )}
    </>
  );
};
