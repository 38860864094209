import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

const TableRowVerification = (props: {
  username: string;
  timestamp: number;
  status: string;
  reason: string;
  fullName: string;
  documentType: string;
  alsoKnownAs: string;
  audience: string;
  requestCategory: string;
  requestId: string;
  type: string;
  userSub: string;
}) => {
  return (
    <Table.Row key={props.requestId}>
      <Table.Cell>{new Date(props.timestamp).toISOString()}</Table.Cell>
      <Table.Cell>{props.reason}</Table.Cell>
      <Table.Cell>{props.type}</Table.Cell>
      <Table.Cell>{props.status}</Table.Cell>
      <Table.Cell>
        <Link to={`/ContentCreator/VER_${props.userSub}_${props.requestId}`}>
          View Request
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRowVerification;
