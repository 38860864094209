import { useHistory } from "react-router";
import { FiChevronLeft } from "react-icons/fi";

export const NavBarTopCustom = (props: {
  buttonLeft?: string;
  buttonRight?: string;
  title: string;
  createSet?: boolean;
}) => {
  const history = useHistory();

  return (
    <div className="nav-bar-top-custom ">
      <FiChevronLeft
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
        size={20}
      />
      {props.title}
      <div
        onClick={() => {}}
        style={{
          color: "#00ABD9",
          border: "none",
          backgroundColor: "transparent",
          outline: "none",
          fontSize: "16px",
          padding: 0,
          margin: 0,
          fontFamily: "OpenSans-reg",
        }}
        className="navbar"
      >
        {props?.buttonRight || ""}
      </div>
    </div>
  );
};
