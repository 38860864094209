interface discoverPreviousActions {
  SET_PREVIOUS_DISCOVER: string;
  INVALID_TAB_NO: string;
}

const discoverPreviousActionsType: discoverPreviousActions = {
  SET_PREVIOUS_DISCOVER: "SET_PREVIOUS_DISCOVER",
  INVALID_TAB_NO: "INVALID_TAB_NO",
};

const INITIAL_STATE = {
  previousDiscoverTab: 0,
};

export const setPreviousTabD = (data: number) => {
  if (data >= 0 && data <= 4) {
    return {
      type: discoverPreviousActionsType.SET_PREVIOUS_DISCOVER,
      payload: data,
    };
  } else {
    return {
      type: discoverPreviousActionsType.INVALID_TAB_NO,
      payload: 0,
    };
  }
};

const discoverPreviousReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case discoverPreviousActionsType.SET_PREVIOUS_DISCOVER:
      return { previousDiscoverTab: action.payload };
    case discoverPreviousActionsType.INVALID_TAB_NO:
      return state;
    default:
      return state;
  }
};

export default discoverPreviousReducer;
