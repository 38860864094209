import VerificationTableGeneral from "./VerificationTableGeneral";

const InfluencerTabFragment = (props: any) => {
  return (
    <VerificationTableGeneral
      moderationDateSort={props.moderationDateSort}
      moderationStatusOptions={props.moderationStatusOptions}
      target="INFLUENCER"
    />
  );
};

export default InfluencerTabFragment;
