import ItemSectionDaily from "../itemSections/itemSectionDaily";
import ItemSection from "../itemSections/itemSection";
import DatesDropdown from "../datesDropdown";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import authorization from "../../../authorization";
import {
  changeAmbassadors,
  changeCounter,
  changeDaily,
  changeNew,
  changePixel,
  changePro,
  changeTimed,
  changeTrending,
  changeWeekly,
} from "../../../redux/selection";
import { useState } from "react";

interface RightSelectionProps {
  currentDate: string;
  isLoading: boolean;
  ChangeCurrentDate: (d: string) => void;
  getDiscoverDataByDate: (d: string) => void;
  currentId: string;
  isArchive: boolean;
  startDate: any;
}

const RightSelection = (props: RightSelectionProps) => {
  const dailySets = useAppSelector((state) => state.selection.daily);
  const timedSets = useAppSelector((state) => state.selection.timed);
  const pixelSets = useAppSelector((state) => state.selection.pixel);
  const weeklySets = useAppSelector((state) => state.selection.weekly);
  const proSetsDiscover = useAppSelector((state) => state.selection.pro);
  const trendingSets = useAppSelector((state) => state.selection.trending);
  const ambassadors = useAppSelector((state) => state.selection.ambassadors);
  const newSets = useAppSelector((state) => state.selection.newSets);
  const dailySetCount = useAppSelector(
    (state) => state.selection.dailysetcount
  );

  const dispatch = useAppDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const sections = [
    "Top Daily Sets",
    "Top Weekly Sets",
    "Trending Sets",
    "New Sets",
    "Timed Sets",
    "Ambassadors",
    "Pro Sets",
    "Pixel Sets",
  ];

  const handleChange = (event: string) => {
    if (!(props.currentDate === event)) {
      dispatch(changeDaily([]));
      dispatch(changeCounter(0));
      dispatch(changeWeekly([]));
      dispatch(changeTrending([]));
      dispatch(changeNew([]));
      dispatch(changeTimed([]));
      dispatch(changeAmbassadors([]));
      dispatch(changePixel([]));
      dispatch(changePro([]));
    }
    props.ChangeCurrentDate(event);
  };

  const useArchiveHandler = () => {
    props.ChangeCurrentDate(props.startDate);
  };

  const handleOnDragEndDaily = (result: any) => {
    if (!result.destination) return;
    const items = dailySets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeDaily(items));
  };

  const handleOnDragEndWeekly = (result: any) => {
    if (!result.destination) return;
    const items = weeklySets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeWeekly(items));
  };
  const handleOnDragEndTrending = (result: any) => {
    if (!result.destination) return;
    const items = trendingSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeTrending(items));
  };

  const handleOnDragNewSets = (result: any) => {
    if (!result.destination) return;
    const items = newSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeNew(items));
  };

  const removeFromDaily = (id: string) => {
    let arr = dailySets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr[index] = { id: "n" + index, title: "" };
    dispatch(changeDaily([...arr]));
    dispatch(changeCounter(dailySetCount - 1));
    console.log(dailySets);
    dailySets.map((item: { id: string; title: string }, index: any) =>
      console.log(item.id + " " + item.title + "  " + index)
    );
  };

  const handleOnDragTimedSets = (result: any) => {
    if (!result.destination) return;
    const items = timedSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeTimed(items));
  };

  const handleOnDragAmbassadors = (result: any) => {
    if (!result.destination) return;
    const items = ambassadors;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changeAmbassadors(items));
  };

  const handleOnDragProSets = (result: any) => {
    if (!result.destination) return;
    const items = proSetsDiscover;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changePro(items));
  };

  const handleOnDragPixelSets = (result: any) => {
    if (!result.destination) return;
    const items = pixelSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(changePixel(items));
  };

  const removeFromWeekly = (id: string) => {
    let arr = weeklySets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changeWeekly([...arr]));
  };

  const removeFromTrending = (id: string) => {
    let arr = trendingSets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changeTrending([...arr]));
  };
  const removeFromTimedSets = (id: string) => {
    let arr = timedSets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changeTimed([...arr]));
  };

  const removeFromAmbassadors = (id: string) => {
    let arr = ambassadors;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changeAmbassadors([...arr]));
  };

  const removeFromProSets = (id: string) => {
    let arr = proSetsDiscover;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changePro([...arr]));
  };

  const removeFromPixelSets = (id: string) => {
    let arr = pixelSets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changePixel([...arr]));
  };

  const removeFromNewSets = (id: string) => {
    let arr = newSets;
    let index = arr.findIndex((v: any) => v.id === id);
    arr.splice(index, 1);
    dispatch(changeNew([...arr]));
  };

  const submitNewData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}createDiscoverData`;
    if (dailySets.length < 7) {
      setErrorMsg("You need 7 daily sets");
      return;
    }
    if (weeklySets.length < 10) {
      setErrorMsg("You need 10 weekly sets");
      return;
    }
    if (trendingSets.length < 10) {
      setErrorMsg("You need 10 trending sets");
      return;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.currentId,
        startDate: new Date(Date.parse(props.currentDate)).toLocaleDateString(
          "en-GB"
        ),
        topDailySets: dailySets.map((item: any) => item.id),
        topWeeklySets: weeklySets.map((item: any) => item.id),
        trendingSnafleSets: trendingSets.map((item: any) => item.id),
        topNewSets: newSets.map((item: any) => item.id),
        topTimedSets: timedSets.map((item: any) => item.id),
        ambassadors: ambassadors.map((item: any) => ({
          id: item.id,
          username: item.title,
        })),
        proSets: proSetsDiscover.map((item: any) => item.id),
        pixelSets: pixelSets.map((item: any) => item.id),
      }),
    }).then((data) => {
      console.log({ status: data.status });
      if (data.status === 204) {
        setSuccessMsg("Submitted");
        setErrorMsg("");
      }
      props.getDiscoverDataByDate(
        new Date(Date.parse(props.currentDate)).toLocaleDateString("en-GB")
      );
    });
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Your Selection</h1>
      <DatesDropdown
        currentDate={props.currentDate}
        changeHandle={handleChange}
      />
      <ItemSectionDaily
        title={sections[0]}
        data={dailySets}
        cap={7}
        isLoading={props.isLoading}
        dailySetCount={dailySetCount}
        handleOnDragEndDaily={handleOnDragEndDaily}
        removefromDaily={removeFromDaily}
      />
      <ItemSection
        title={sections[1]}
        data={weeklySets}
        cap={10}
        deleteMethod={removeFromWeekly}
        dragMethod={handleOnDragEndWeekly}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[2]}
        data={trendingSets}
        cap={10}
        deleteMethod={removeFromTrending}
        dragMethod={handleOnDragEndTrending}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[3]}
        data={newSets}
        cap={10}
        deleteMethod={removeFromNewSets}
        dragMethod={handleOnDragNewSets}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[4]}
        data={timedSets}
        cap={5}
        deleteMethod={removeFromTimedSets}
        dragMethod={handleOnDragTimedSets}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[6]}
        data={proSetsDiscover}
        cap={20}
        deleteMethod={removeFromProSets}
        dragMethod={handleOnDragProSets}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[7]}
        data={pixelSets}
        cap={10}
        deleteMethod={removeFromPixelSets}
        dragMethod={handleOnDragPixelSets}
        isLoading={props.isLoading}
      />
      <ItemSection
        title={sections[5]}
        data={ambassadors}
        cap={5}
        deleteMethod={removeFromAmbassadors}
        dragMethod={handleOnDragAmbassadors}
        isLoading={props.isLoading}
      />
      <p style={{ textAlign: "center", color: "red" }}>{errorMsg}</p>
      <p style={{ textAlign: "center", color: "green" }}>{successMsg}</p>

      {!props.isArchive && (
        <button
          className="button-discover"
          style={{
            justifySelf: "flex-end",
            padding: "4%",
          }}
          onClick={() => {
            submitNewData();
          }}
          disabled={
            dailySetCount < 7 ||
            weeklySets.length < 10 ||
            trendingSets.length < 10 ||
            newSets.length < 5
          }
        >
          Save
        </button>
      )}
      {props.isArchive && (
        <button
          className="button-discover"
          style={{
            justifySelf: "flex-end",
            padding: "4%",
          }}
          onClick={useArchiveHandler}
        >
          Use Achived Data
        </button>
      )}
    </div>
  );
};

export default RightSelection;
