import classes from "./NotificationDot.module.css";

const NotificationDot = () => {
  return (
    <div className={classes.Dot}>
      <h3 className={classes.Text}>!</h3>
    </div>
  );
};

export default NotificationDot;
