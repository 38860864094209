import IndividalFilter from "../../UI/ReusedFilters/individualFilter";
import { useAppSelector } from "../../../hooks";

interface ActivitiesFilterProps {
  onActivityChange: (value: any) => void;
  currentActivity: string;
}

const ActivitiesFilter = (props: ActivitiesFilterProps) => {
  const activitiesRaw = useAppSelector((state) => state.activity).data;
  const activitiesOptions = activitiesRaw.map((act: any) => {
    return { key: act.id, value: act.name, text: act.name };
  });
  activitiesOptions.push({
    key: "All Activities",
    value: "AllActivities",
    text: "All Activities",
  });

  return (
    <IndividalFilter
      title="Activities"
      options={activitiesOptions}
      placeholder="Choose Activity"
      onChange={props.onActivityChange}
      currentValue={props.currentActivity}
    />
  );
};

export default ActivitiesFilter;
