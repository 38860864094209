import VerificationTableGeneral from "./VerificationTableGeneral";

const BrandTabFragment = (props: any) => {
  return (
    <VerificationTableGeneral
      moderationDateSort={props.moderationDateSort}
      moderationStatusOptions={props.moderationStatusOptions}
      target="BRAND"
    />
  );
};

export default BrandTabFragment;
