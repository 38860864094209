import { AddCatComp } from "../components/AddCategories/addCategories.component";
import PageShell from "../components/UI/PageShell/PageShell";

export const CategoriesScreen = () => {
  return (
    <PageShell title="Manage Categories">
      <AddCatComp />
    </PageShell>
  );
};

export default CategoriesScreen;
