import { Tab } from "semantic-ui-react";
import { NavBar } from "..";
import BugsFragment from "./tabs/bugsFragment";
import FeedbackFragment from "./tabs/feedbackFragment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPreviousTab } from "../../redux/previousUserFeed";

const UserFeedBack = () => {
  const dispatch = useAppDispatch();
  const previousTab = useAppSelector(
    (state) => state.feedbackPrevious.previousFeedbackTab
  );

  const TabChangeHandler = (e: any, data: any) => {
    dispatch(setPreviousTab(data.activeIndex));
  };

  console.log(previousTab);
  const panes = [
    {
      menuItem: "Bugs",
      render: () => <BugsFragment />,
    },
    { menuItem: "Feedback", render: () => <FeedbackFragment /> },
  ];

  return (
    <div className="mod-main-conatiner">
      <Tab
        panes={panes}
        renderActiveOnly={true}
        defaultActiveIndex={previousTab}
        onTabChange={TabChangeHandler}
      />
    </div>
  );
};

export default UserFeedBack;
