import styled from "styled-components";

export const NavbarContainer = styled.div`
  position: fixed;
  height: 8vh;
  display: flex;
  flex-flow: row;
  padding:0% 5% 
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
  font-family: OpenSans-Reg;
  z-index: 5;
`;

export const ButtonInvisible = styled.button`
  border: none;
  background-color: white;
  outline: none;
  font-size: 1.2em;

  @media (min-width: 750px) {
    display: none;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-flow: "column";
  align-items: "center";
  justify-content: "space-evenly";
  height: "80%";
  width: "100%";
  padding: 5px;
`;

export const LogoutButton = styled.button`
  border: "none";
  background-color: "white";
  outline: "none";
  fontsize: "1.2em";
  cursor: "pointer";
`;
