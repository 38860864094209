interface DiscoverButtonProps {
  item: any;
  addSelection: (d: { id: string; title: string }) => void;
  text: string;
  setSelection: any[];
  cap: number;
}

const DiscoverButton = (props: DiscoverButtonProps) => {
  const DiscoverClickHandler = (e: any) => {
    props.addSelection({ id: props.item.id, title: props.item.title });
    e.stopPropagation();
  };

  const isDisabled =
    props.setSelection.findIndex((v: any) => v.id === props.item.id) >= 0 ||
    props.setSelection.length === props.cap;

  return (
    <button
      className="button-discover"
      onClick={DiscoverClickHandler}
      disabled={isDisabled}
    >
      {props.text}
    </button>
  );
};

export default DiscoverButton;
