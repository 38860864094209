import { Form } from "semantic-ui-react";

const AddDescription = (props: any) => {
  const onDescChange = (val: any) => {
    props.descChange(val.target.value);
  };

  return (
    <div>
      <Form.Field required>
        <label>Description</label>
        <textarea
          required={true}
          placeholder="Describe what your Snafle Set Involves? Include rules, instructions or tips!"
          id="SetDesc"
          className=""
          name="SetDesc"
          value={props.setDesc}
          onChange={onDescChange}
          style={{ resize: "none" }}
        ></textarea>
      </Form.Field>
      <p style={{ fontSize: "0.8em", color: "var(--grey-color)" }}>
        Add #tags to help users discover you set
      </p>
    </div>
  );
};

export default AddDescription;
