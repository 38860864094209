import { CustomButton } from "../custom-btn/cstm-btn.component";
import classes from "./CreateSetButton.module.css";
import { useAppDispatch } from "../../../hooks";
import { deleteCurrentSet } from "../../../redux/currentSet";
import { deleteCurrentItem } from "../../../redux/currentItem";

const CreateSetButton = () => {
  const dispatch = useAppDispatch();

  dispatch(deleteCurrentSet());
  dispatch(deleteCurrentItem());

  return (
    <div className={classes.buttonHolder}>
      <CustomButton buttonTitle="Create Set" connection="/createSnafleSet" />
    </div>
  );
};

export default CreateSetButton;
