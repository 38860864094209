import { Form } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { FiCamera } from "react-icons/fi";
import { useAppSelector } from "../../../hooks";

const AddImage = (props: any) => {
  const history = useHistory();

  const image = useAppSelector((state) => state.image.data) || {
    author: "",
    url: "",
    downloadLink: "",
  };

  return (
    <Form.Field>
      <div
        onClick={() => {
          history.push("/editSnafleItem/setImage");
        }}
        style={
          image?.url.length > 0
            ? {
                backgroundImage: `url(${props.itemImage.url})`,
                backgroundColor: "transparent",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "40vh",
              }
            : {}
        }
        className="placeholder-image"
      >
        <FiCamera
          size={80}
          color="#a6a6a6"
          style={image?.url.length > 0 ? { display: "none" } : {}}
        />
        <p
          style={
            image?.url.length > 0 ? { display: "none" } : { color: "#a6a6a6" }
          }
        >
          Touch to add an item image
        </p>
      </div>
    </Form.Field>
  );
};
export default AddImage;
