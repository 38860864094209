import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadCurrentCatAct } from "../../redux/currentCatAct";
import { fas } from "@fortawesome/free-solid-svg-icons";
import authorization from "../../authorization";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fas);

type Category = { id: string; name: string };

export const AddActComp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const activities = useAppSelector((state) => state.activity.data);
  const [act, setAct] = useState([
    { id: "", name: "", assetName: "", iconType: "" },
  ]);
  const [value, setValue] = useState("");
  const [responseText, setResponseText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [newData, setNewData] = useState(false);
  const compareStrings = (a: string, b: string) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    let activitiesSorted = activities;
    activitiesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });
    setAct(activitiesSorted);
  }, []);

  useEffect(() => {
    const getActivities = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getActivities`;
      fetch(url, { headers: { ...(await authorization()) } })
        .then((data) => data.json())
        .then((data) => {
          setAct(
            data.sort((a: Category, b: Category) => {
              return compareStrings(a.name, b.name);
            })
          );
        });
    };
    getActivities();
  }, [newData]);

  const addActivity = async () => {
    const body = {
      name: value,
    };
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}createActivity`;
    const data = await fetch(url, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(async (data) => {
      if (data.status === 204) {
        setResponseText("Activity inserted into the database");
        setErrorText("");
        setAct([{ id: "", name: value, assetName: "", iconType: "" }, ...act]);
        setNewData(!newData);
      } else {
        setResponseText("");
        setErrorText("Activity already exists");
      }
    });
  };

  const deleteActivity = async (id: string) => {
    const body = {
      id,
    };

    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}deleteActivity`;
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(() => {
      setResponseText("Activity Deleted");
      setNewData(!newData);
    });
  };

  return (
    <div>
      <div style={{ paddingLeft: "40%" }}>
        <Input
          key="AutocompleteId"
          id="AutocompleteId"
          placeholder="Add Activities"
          style={{ paddingTop: "10%" }}
          value={value}
          onChange={(val) => {
            setValue(val.target.value);
          }}
          action={{
            icon: "plus",
            onClick: () => {
              addActivity();
            },
          }}
        />
        <p className="smallText" style={{ color: "green" }}>
          {responseText}
        </p>
        <p className="smallText" style={{ color: "red" }}>
          {errorText}
        </p>
      </div>
      <div className="container">
        <h4 style={{ textAlign: "center" }}>Current Activities</h4>

        <div className="current-cat-container">
          {act.map((index) => (
            <div
              className="current-categories"
              key={index.id}
              onClick={() => {
                dispatch(loadCurrentCatAct(index));
                history.push("/addActivities/addImage");
              }}
            >
              <div className="inputContainer">
                <FontAwesomeIcon
                  //@ts-ignore
                  icon={[index.iconType, index.assetName]}
                  size="5x"
                />
              </div>
              {index.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
