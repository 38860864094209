import { Table } from "semantic-ui-react";
import TableRowsReport from "../tableRowsReport";

interface SumamryProps {
  currentReport: any;
  currentReportType: string;
  imageUrl: string;
}

const DetailsSummary = (props: SumamryProps) => {
  return (
    <div>
      <h2>Report Details</h2>
      <Table definition celled striped>
        <Table.Body>
          <TableRowsReport
            data={props.currentReport}
            currentReportType={props.currentReportType}
            imageUrl={props.imageUrl}
          />
        </Table.Body>
      </Table>
    </div>
  );
};

export default DetailsSummary;
