import { useState, useEffect } from "react";
import { Table, Tab, Dropdown, Button, Input } from "semantic-ui-react";
import TableRowsMod from "../TableParts/TableRowsMod";
import authorization from "../../../authorization";

interface ModerationTableProps {
  moderationSourceType: any;
  moderationDateSort: any;
  moderationStatusOptions: any;
}

export const ModerationTable = (props: ModerationTableProps) => {
  const [dataRaw, setDataRaw] = useState<any>([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [username, setUsername] = useState<string>("");
  const [moderationStatus, setModerationStatus] = useState<string>("ALL");
  const [sourceType, setSourceType] = useState("ALL");

  const [modTime, setModTime] = useState("NORM");
  const [reviewedTime, setRevTime] = useState("NORM");

  const handleUsernameSearch = () => {
    FilterDisplay();
  };

  const handleUsernameSearchValue = (event: any, item: any) => {
    setUsername(item.value);
  };

  const handleStatusChange = (event: any, item: any) => {
    setModerationStatus(item.value);
  };

  const handleSourceTypeChange = (event: any, item: any) => {
    setSourceType(item.value);
  };

  const orderTime = (
    attributeName: string,
    type: "NEWEST" | "OLDEST" | "NORM"
  ) => {
    if (
      username ||
      modTime !== "NORM" ||
      reviewedTime !== "NORM" ||
      moderationStatus !== "ALL" ||
      sourceType !== "ALL"
    ) {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] < b[attributeName]
              ? -1
              : a[attributeName] > b[attributeName]
              ? 1
              : 0
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] > b[attributeName]
              ? -1
              : a[attributeName] < b[attributeName]
              ? 1
              : 0
          )
        );
      }
    } else {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] < b[attributeName]
              ? -1
              : a[attributeName] > b[attributeName]
              ? 1
              : 0
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort((a: any, b: any) =>
            a[attributeName] > b[attributeName]
              ? -1
              : a[attributeName] < b[attributeName]
              ? 1
              : 0
          )
        );
      }
    }
  };

  const handleModerationTimeSort = (event: any, item: any) => {
    orderTime("moderationTimestamp", item.value);
    setModTime(item.value);
  };

  const handleModerationReviewedTimeSort = (event: any, item: any) => {
    orderTime("reviewedTimestamp", item.value);
    setRevTime(item.value);
  };

  useEffect(() => {
    getModerationData();
  }, []);

  const getModerationData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationData`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setDataRaw(res.items);
        setDataDisplay(res.items);
      });
  };

  const FilterDisplay = () => {
    let temp = dataRaw;

    if (sourceType !== "ALL") {
      temp = temp.filter(
        (line: any) => line.sourceType === sourceType.toLowerCase()
      );
    }

    if (moderationStatus !== "ALL") {
      temp = temp.filter((line: any) => line.status === moderationStatus);
    }

    if (username?.trim() !== "") {
      temp = temp.filter((x: any) =>
        x.username.toLowerCase().includes(username?.toLowerCase())
      );
    }

    setDataDisplay(temp);
  };

  useEffect(() => {
    FilterDisplay();
  }, [sourceType, moderationStatus]);

  return (
    <Tab.Pane>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              Username{" "}
              <Input
                placeholder="Filter Username"
                action={{ icon: "search", onClick: handleUsernameSearch }}
                onChange={handleUsernameSearchValue}
                value={username}
              />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Source Type
              <div>
                <Dropdown
                  options={props.moderationSourceType}
                  value={sourceType}
                  onChange={handleSourceTypeChange}
                  selection
                  fluid
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={1}>
              Media Type
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Moderation Reason
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Moderation Timestamp
              <div>
                <Dropdown
                  options={props.moderationDateSort}
                  value={modTime}
                  onChange={handleModerationTimeSort}
                  selection
                  fluid
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}} width={2}>
              Reviewed Timestamp
              <div>
                <Dropdown
                  options={props.moderationDateSort}
                  value={reviewedTime}
                  onChange={handleModerationReviewedTimeSort}
                  selection
                  fluid
                />
              </div>
            </Table.HeaderCell>

            <Table.HeaderCell onClick={() => {}} width={2}>
              Moderation Status
              <div>
                <Dropdown
                  options={props.moderationStatusOptions}
                  value={moderationStatus}
                  onChange={handleStatusChange}
                  selection
                  fluid
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              View
              <div>
                <Button
                  primary
                  onClick={() => {
                    setDataDisplay(dataRaw);
                    setModTime("NORM");
                    setRevTime("NORM");
                    setSourceType("ALL");
                    setModerationStatus("ALL");
                    setUsername("");
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <TableRowsMod data={dataDisplay} />
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};
export default ModerationTable;
