import { NavbarContainer } from "./navbar.styles";
import LogoutButton from "./logoutButton.component";
import SnaflerHome from "./snaflerHome.component";
import MaintenanceToggle from "./maintainenceToggle.component";

export const NavBarMaintainace = () => {
  return (
    <NavbarContainer>
      <SnaflerHome />
      <div className="nav-web">
        <LogoutButton />
        <MaintenanceToggle />
      </div>
    </NavbarContainer>
  );
};
