import DiscoverButton from "./DiscoverButtons";
import GeneralCard from "./GeneralCard";

interface SetPixelProps {
  item: any;
  pixelSets: { id: string; title: string }[];
  addToPixelSets: (data: { id: string; title: string }) => void;
}

export const SetPixel = (props: SetPixelProps) => {
  return (
    <GeneralCard targetId={props.item.id}>
      <h2>{props.item.title}</h2>
      <div className="button-group">
        <DiscoverButton
          addSelection={props.addToPixelSets}
          cap={10}
          item={props.item}
          setSelection={props.pixelSets}
          text="Add to Pixel Sets"
        />
      </div>
    </GeneralCard>
  );
};

export default SetPixel;
