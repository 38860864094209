import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TableRowReport = (props: {
  sourceType: string;
  reportCategory: string;
  timestamp: string;
  userMessage: string;
  userSub: string;
  status: string;
  sourceId: string;
}) => {
  const location = useLocation();
  // console.log({ userSub: props.username });
  return (
    <Table.Row key={props.sourceId + props.userSub}>
      {/* <Table.Cell>{props.username}</Table.Cell> */}
      <Table.Cell>{props.sourceType.toUpperCase()}</Table.Cell>
      <Table.Cell>{props.reportCategory}</Table.Cell>
      <Table.Cell>{props.timestamp}</Table.Cell>
      <Table.Cell>{props.userMessage}</Table.Cell>
      <Table.Cell>{props.status}</Table.Cell>
      <Table.Cell>
        <Link
          to={`${location.pathname}/REP_${props.userSub}_${props.sourceId}`}
        >
          View Report
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRowReport;
