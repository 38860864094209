interface ButtonGeneralProps {
  renderType: string;
  btnType: string;
  btnText: string;
  updateFunction: (
    a: any,
    b: any,
    c: any,
    d: string,
    e: string,
    f: any
  ) => void;
  userSub: string;
  sourceId: string;
  modComments: string;
  dropdown: string;
  type: string;
}

const ButtonGeneral = (props: ButtonGeneralProps) => {
  const onClickHandler = () => {
    props.updateFunction(
      props.userSub,
      props.sourceId,
      props.btnType,
      props.modComments,
      props.dropdown,
      props.type
    );
  };

  return (
    <button className={props.renderType} onClick={onClickHandler}>
      {props.btnText}
    </button>
  );
};

export default ButtonGeneral;
