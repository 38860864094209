import { Table } from "semantic-ui-react";

const TableRowSocialMedia = (props: { socialMediaAccounts: any }) => {
  console.log(props);
  return (
    <>
      <Table.Row>
        <Table.Cell>Instagram</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.instagram}>
            {props.socialMediaAccounts?.instagram}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Facebook</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.facebook}>
            {props.socialMediaAccounts?.facebook}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Twitter</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.twitter}>
            {props.socialMediaAccounts?.twitter}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Medium</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.medium}>
            {props.socialMediaAccounts?.medium}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>LinkedIn</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.linkedIn}>
            {props.socialMediaAccounts?.linkedIn}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Personal Website</Table.Cell>
        <Table.Cell>
          <a href={props.socialMediaAccounts?.website}>
            {props.socialMediaAccounts?.website}
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Other Sites</Table.Cell>
        <Table.Cell>
          {props?.socialMediaAccounts?.other?.map((x: string) => (
            <a href={x}>{x},</a>
          ))}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default TableRowSocialMedia;
