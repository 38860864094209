import { Table } from "semantic-ui-react";

const TableRowReportVER = (props: {
  requestId: string;
  username: string;
  userSub: string;
  type: string;
  moderationTimestamp: string;
  fullName: string;
  requestCategory: string;
  status: string;
  modifiedBy: string;
  reviewedTimestamp: string;
  detectionLabels: any;
  moderatorComments: string;
  mod: string;
  audience: string;
  timestamp: number;
  documentType: string;
  socialMediaAccount: any;
  imageUrl: string;
}) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>Username</Table.Cell>
        <Table.Cell>{props.username}</Table.Cell>
      </Table.Row>
      {props?.type === "VERIFICATION" ? (
        <Table.Row>
          <Table.Cell>Request Category</Table.Cell>
          <Table.Cell>{props.requestCategory?.toUpperCase()}</Table.Cell>
        </Table.Row>
      ) : (
        <></>
      )}

      <Table.Row>
        <Table.Cell>Request Type</Table.Cell>
        <Table.Cell>{props.type}</Table.Cell>
      </Table.Row>
      {props?.type === "VERIFICATION" ? (
        <>
          <Table.Row>
            <Table.Cell>Full Name</Table.Cell>
            <Table.Cell>{props.fullName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Audience</Table.Cell>
            <Table.Cell>{props.audience}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Document Type</Table.Cell>
            <Table.Cell>
              <a href={props.imageUrl} download>
                {props?.documentType?.toUpperCase()}
              </a>
            </Table.Cell>
          </Table.Row>
        </>
      ) : (
        <></>
      )}

      <Table.Row>
        <Table.Cell>Request Timestamp</Table.Cell>
        <Table.Cell>
          {new Date(props?.timestamp).toLocaleString("en-GB") || ""}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Request Status</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
      </Table.Row>

      {props.reviewedTimestamp ? (
        <>
          {" "}
          <Table.Row>
            <Table.Cell>Reviewed Timestamp</Table.Cell>
            <Table.Cell>
              {new Date(props?.reviewedTimestamp).toLocaleString("en-GB")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Reviewer</Table.Cell>
            <Table.Cell>{props.mod}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Comments</Table.Cell>
            <Table.Cell>{props.moderatorComments}</Table.Cell>
          </Table.Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableRowReportVER;
