import { useEffect, useState } from "react";
import { Table, Tab, Dropdown, Button } from "semantic-ui-react";
import TableRowsVerification from "./BlueCheckTableRows";
import authorization from "../../../authorization";

interface VerificationTableProps {
  moderationDateSort: any;
  moderationStatusOptions: any;
}

export const VerificationTable = (props: VerificationTableProps) => {
  const [dataRaw, setDataRaw] = useState<any>([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [moderationStatus, setModerationStatus] = useState("ALL");

  const [modTime, setModTime] = useState("NORM");

  const orderTime = (
    attributeName: string,
    type: "NEWEST" | "OLDEST" | "NORM"
  ) => {
    if (modTime !== "NORM" || moderationStatus !== "ALL") {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => a[attributeName] - b[attributeName]
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => b[attributeName] - a[attributeName]
          )
        );
      }
    } else {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => a[attributeName] - b[attributeName]
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => b[attributeName] - a[attributeName]
          )
        );
      }
    }
  };

  const handleModerationTimeSort = (event: any, item: any) => {
    orderTime("timestamp", item.value);
    setModTime(item.value);
  };

  const handleStatusChange = (event: any, item: any) => {
    if (item.value === "ALL") {
      setDataDisplay(dataRaw);
      setModerationStatus("ALL");
      return;
    }

    setModerationStatus(item.value);
    if (modTime !== "NORM") {
      setDataDisplay(dataRaw.filter((x: any) => x.status === item.value));
    } else {
      setDataDisplay(dataRaw.filter((x: any) => x.status === item.value));
    }
  };

  const getVerificationRequests = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=VERIFICATION`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        setDataDisplay(res.items);
        setDataRaw(res.items);
      });
  };

  useEffect(() => {
    getVerificationRequests();
  }, []);

  return (
    <Tab.Pane>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell onClick={() => {}}>
              Request Timestamp
              <div>
                <Dropdown
                  options={props.moderationDateSort}
                  selection
                  fluid
                  value={modTime}
                  onChange={handleModerationTimeSort}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>User Message</Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>Fullname</Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>
              Document Type
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>
              Also Known As
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>Audience</Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>
              Request Category
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => {}}>
              Request Status
              <div>
                <Dropdown
                  options={props.moderationStatusOptions}
                  selection
                  fluid
                  value={moderationStatus}
                  onChange={handleStatusChange}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>
              View
              <div>
                <Button
                  primary
                  onClick={() => {
                    setDataDisplay(dataRaw);
                    setModTime("NORM");
                    setModerationStatus("ALL");
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <TableRowsVerification data={dataDisplay} />
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

export default VerificationTable;
