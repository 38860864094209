import DiscoverButton from "./DiscoverButtons";
import GeneralCard from "./GeneralCard";

export const SetTimed = (props: {
  item: any;
  timedSets: { id: string; title: string }[];
  addToTimedSets: (data: { id: string; title: string }) => void;
}) => {
  return (
    <GeneralCard targetId={props.item.id}>
      <h2>{props.item.title}</h2>
      <div className="button-group">
        <DiscoverButton
          addSelection={props.addToTimedSets}
          item={props.item}
          text="Add to Timed Sets"
          cap={5}
          setSelection={props.timedSets}
        />
      </div>
    </GeneralCard>
  );
};

export default SetTimed;
