import React from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, MenuImage } from "./menu.styles";
import authorization from "../../../authorization";

export const MenuItemComp = (props: {
  title: string;
  id: string;
  description: string;
  dataPassThrough: string;
  setId: string;
}) => {
  const history = useHistory();

  const deleteItem = async (itemId: string) => {
    const url = `https://q71uh9trvd.execute-api.eu-west-1.amazonaws.com/dev/deleteItem`;

    const data = {
      id: props.setId,
      itemId: itemId,
    };

    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  return (
    <MenuItem>
      <MenuImage
        onClick={() => {
          history.push(`/${props.dataPassThrough}/${props.id}`);
        }}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.id}.jpg)`,
        }}
      >
        <h3 style={{ color: "white" }}>{props.title}</h3>
      </MenuImage>
    </MenuItem>
  );
};
