import { Table } from "semantic-ui-react";

const ReportTableRow = (props: { data: any }) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>Username</Table.Cell>
        <Table.Cell>{props?.data?.username}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Message</Table.Cell>
        <Table.Cell>{props?.data?.userMessage}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Timestamp</Table.Cell>
        <Table.Cell>
          {new Date(props?.data?.timestamp).toLocaleString("en-GB")}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>OS</Table.Cell>
        <Table.Cell>
          {props?.data?.OS} {props?.data?.OSVersion}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Snafler Build Number</Table.Cell>
        <Table.Cell>
          {props?.data?.snaflerVersion}({props?.data?.snaflerBuildNumber})
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Phone Brand</Table.Cell>
        <Table.Cell>{props?.data?.brand}</Table.Cell>
      </Table.Row>
    </>
  );
};

export default ReportTableRow;
