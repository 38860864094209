import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { loadCurrentCatAct } from "../../redux/currentCatAct";

const RenderComponent = React.memo(
  (props: { item: { id: string; name: string; imageUrl: string } }) => {
    const Image = ({ alt, item }: any) => {
      const history = useHistory();
      const dispatch = useAppDispatch();

      const [error, setError] = useState(false);

      const onError = (err: string) => {
        setError(true);
        console.log(err);
      };

      return error ? (
        <img
          src={`https://image-bucket-1-dev.s3.eu-west-1.amazonaws.com/snafler-logo.png`}
          alt={alt}
          className="imageOverlay"
          onError={(e) => onError(e.currentTarget.innerText)}
          onClick={() => {
            dispatch(loadCurrentCatAct(item));
            history.push("/addCategories/addImage");
          }}
        />
      ) : (
        <img
          src={`https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${
            item.id
          }.jpg?${Math.random()}`}
          alt={alt}
          className="imageOverlay"
          onError={(e) => onError(e.currentTarget.innerText)}
          onClick={() => {
            dispatch(loadCurrentCatAct(item));
            history.push("/addCategories/addImage");
          }}
        />
      );
    };

    return (
      <div className="current-categories" key={props.item.id}>
        <Image item={props.item} alt="Category Image" />
        <div className="itemText">{props.item.name}</div>
      </div>
    );
  }
);

export default RenderComponent;
