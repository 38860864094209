import { Table } from "semantic-ui-react";

const TableRowReportREP = (props: {
  sourceId: string;
  username: string;
  userSub: string;
  sourceType: string;
  reportCategory: string;
  modifiedBy: string;
  reviewedTimestamp: string;
  moderatorComments: string;
  mod: string;
  timestamp: number;
  userMessage: string;
}) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>Reporter</Table.Cell>
        <Table.Cell>{props.username}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Request Category</Table.Cell>
        <Table.Cell>{props.reportCategory?.toUpperCase()}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Source Type</Table.Cell>
        <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>User Report Reason</Table.Cell>
        <Table.Cell>{props.userMessage}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Request Timestamp</Table.Cell>
        <Table.Cell>
          {new Date(props?.timestamp).toLocaleString("en-GB") || ""}
        </Table.Cell>
      </Table.Row>

      {props.reviewedTimestamp ? (
        <>
          {" "}
          <Table.Row>
            <Table.Cell>Reviewed Timestamp</Table.Cell>
            <Table.Cell>
              {new Date(props?.reviewedTimestamp).toLocaleString("en-GB")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Reviewer</Table.Cell>
            <Table.Cell>{props.mod}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Comments</Table.Cell>
            <Table.Cell>{props.moderatorComments}</Table.Cell>
          </Table.Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableRowReportREP;
