/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7c89f9a3-f475-4747-9bfe-0583037b72da",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_n6E6B1JEn",
    "aws_user_pools_web_client_id": "3jngjiolt5g3rgbpne2vs9h2o",
    "oauth": {},
    "aws_user_files_s3_bucket": "snafleruserdatastore120725-qa",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "9204a3f8f45a4a0fb5c9468a19c902a3",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
