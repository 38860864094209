import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

const TableRowReport = (props: {
  sourceType: string;
  reportCategory: string;
  timestamp: string;
  userMessage: string;
  userSub: string;
  sourceId: string;
}) => {
  return (
    <Table.Row key={props.sourceId + props.userSub}>
      <Table.Cell>{props.sourceType.toUpperCase()}</Table.Cell>
      <Table.Cell>{props.reportCategory}</Table.Cell>
      <Table.Cell>{props.timestamp}</Table.Cell>
      <Table.Cell>{props.userMessage}</Table.Cell>
      <Table.Cell>
        <Link to={`/viewFeedback/${props.sourceId}_${props.userSub}`}>
          View Report
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRowReport;
