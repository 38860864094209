import { Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { HiOutlineLocationMarker } from "react-icons/hi";

const AddLocation = (props: any) => {
  return (
    <Form.Field>
      <label>Location</label>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          border: "1px solid var(--light-grey)",
          borderRadius: "5px",
          alignItems: "center",
          padding: "3% 5%",
          justifyContent: "space-between",
        }}
      >
        <div className="inner-location">
          <HiOutlineLocationMarker size={20} />
          {props.location?.name.length > 0
            ? props.location.name
            : "Select Location"}
        </div>
        <Link to="/createSnafleItem/setLocation">Change</Link>
      </div>
    </Form.Field>
  );
};

export default AddLocation;
