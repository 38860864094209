import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiSettings } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import classes from "../Set/set.module.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useAppDispatch } from "../../hooks";
import { fetchCurrentItemData } from "../../redux/currentItem";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Maps } from "../maps/maps.component";
import authorization from "../../authorization";
import Parragrapher from "../UI/Paragrapher/Paragraher";
export const ItemComponent = (props: any) => {
  const history = useHistory();

  const [itemTitle, setItemTitle] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [activityType, setActivityType] = useState("");

  const [itemLocation, setItemLocation] = useState({
    name: "",
    formatted_address: "",
    country_code: "",
    lng: 0,
    lat: 0,
    photo_reference: "",
    type: "",
  });

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [tags, setTags] = useState([""]);

  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const url = `https://ttxonvaqwj.execute-api.eu-west-1.amazonaws.com/dev/getSetItem?id=${props.match.params.id}`;
  const fetchItemData = async () => {
    console.log("GO");
    return await fetch(url, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setItemTitle(data.title);
        setItemDesc(data.description);
        setItemLocation(data.location);
        setTags(checkTags(data.title, data.tags));
        setActivityType(data.activityType);
      });
  };

  const checkTags = (title: string, tags: string[]) => {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i] === title) {
        tags.splice(i, 1);
      }
    }
    return tags;
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const ItemBody = styled.div`
    display: flex;
    flex-flow: column;
    margin: 5%;
  `;

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchItemData();
    dispatch(fetchCurrentItemData(url));
  }, [ItemComponent]);

  useEffect(() => {}, []);

  const containerStyle = {
    width: "100%",
    height: "70%",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  };

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  console.log(itemDesc);

  return (
    <div>
      {isLoading ? (
        <div className="loadingScreen">
          <ScaleLoader />
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.match.params.id}.jpg)`,
              height: "40vh",
              width: "100%",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onLoad={handleImageLoaded}
            className="image-banner"
          >
            <div className={classes.NavContainer}>
              <FiChevronLeft
                style={{ cursor: "pointer" }}
                color="white"
                size={24}
                onClick={() => {
                  history.goBack();
                }}
              />
              <FiSettings
                style={{ cursor: "pointer" }}
                color="white"
                size={24}
                onClick={() => {
                  history.push(`/editItem/${props.match.params.id}`);
                }}
              />
            </div>

            <div className={classes.TopContainer}>
              <h2>{itemTitle}</h2>
            </div>

            {/**
             * image
             */}
          </div>
          <div
            className="item-container"
            style={{ display: "flex", flexFlow: "column" }}
          >
            {/**
             * body
             */}
            <h3>Description</h3>
            <Parragrapher text={itemDesc} />
            <div style={{ margin: "8% 0" }}>
              <h3>Location</h3>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "space-between",
                  boxShadow: "0px 3px 5px var(--grey-color)",
                  borderRadius: "10px",
                  margin: "1% 0",
                }}
              >
                {/**
                 * map
                 */}

                <Maps data={itemLocation} />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "space-between",
                    margin: "5%",
                    minHeight: "15%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "flexStart",
                      alignItems: "flexStart",
                    }}
                  >
                    <HiOutlineLocationMarker />
                    <p style={{ paddingLeft: "10px" }}>{itemLocation.name}</p>
                  </div>
                </div>
              </div>
              <div className="tag-cont">
                {tags.length > 0 ? (
                  <>
                    <h3>Tags</h3>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row wrap",
                      }}
                    >
                      {tags.map((index) => (
                        <div
                          style={{
                            fontSize: "0.7em",
                            color: "white",
                            padding: "1% 4%",
                            borderRadius: "20px",
                            backgroundColor: "var(--primary-color)",
                          }}
                          className="tag"
                        >
                          {"#" + index}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
