interface moderationPreviousActions {
  SET_PREVIOUS_MODERATION: string;
  INVALID_TAB_NO: string;
}

const moderationPreviousActionsType: moderationPreviousActions = {
  SET_PREVIOUS_MODERATION: "SET_PREVIOUS_MODERATION",
  INVALID_TAB_NO: "INVALID_TAB_NO",
};

const INITIAL_STATE = {
  previousModerationTab: 1,
};

export const setPreviousTabM = (data: number) => {
  if (data >= 0 && data <= 3) {
    return {
      type: moderationPreviousActionsType.SET_PREVIOUS_MODERATION,
      payload: data,
    };
  } else {
    return {
      type: moderationPreviousActionsType.INVALID_TAB_NO,
      payload: 0,
    };
  }
};

const moderationPreviousReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case moderationPreviousActionsType.SET_PREVIOUS_MODERATION:
      return { previousModerationTab: action.payload };
    case moderationPreviousActionsType.INVALID_TAB_NO:
      return state;
    default:
      return state;
  }
};

export default moderationPreviousReducer;
