import { useState, useEffect } from "react";
import { Tab, Table } from "semantic-ui-react";
import TableRowsReport from "../table/tableRowsReport";
import authorization from "../../../authorization";

const ReportTableGeneral = (props: any) => {
  const [data, setData] = useState<any>([]);
  const getReports = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports?type=${props.reportType}`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setData(res.items);
      });
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <Tab.Pane>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Source Type</Table.HeaderCell>
            <Table.HeaderCell>Report Category</Table.HeaderCell>
            <Table.HeaderCell>Report Timestamp</Table.HeaderCell>
            <Table.HeaderCell>User's Message</Table.HeaderCell>
            <Table.HeaderCell>View</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <TableRowsReport data={data} />
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

export default ReportTableGeneral;
