interface previousPageActions {
  SET_PREVIOUS_PAGE: string;
}

const previousPageActionsType: previousPageActions = {
  SET_PREVIOUS_PAGE: "SET_PREVIOUS_PAGE",
};

const INITIAL_STATE = {
  previousPageCreateSet: false,
};

export const setpreviouspage = (data: boolean) => ({
  type: previousPageActionsType.SET_PREVIOUS_PAGE,
  payload: data,
});

const previouspageReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case previousPageActionsType.SET_PREVIOUS_PAGE:
      return { previousPageCreateSet: action.payload };
    default:
      return state;
  }
};

export default previouspageReducer;
