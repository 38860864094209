import React, { useEffect } from "react";
import "./missionControl.css";
import { FaUsers } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import { BsCollectionFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { NavBarMaintainace } from "../UI/Navbar";
import authorization from "../../authorization";
import { useState } from "react";
import NotificationDot from "../UI/NotificationDot/NotificationDot";

const MissionControl = () => {
  const dateP = new Date("Oct 30, 2021 10:30:00").getTime();

  const [date, setDate] = useState("Loading");
  const [users, setUsers] = useState(0);
  const [sets, setSets] = useState(0);
  const [posts, setPosts] = useState(0);
  const [changeUser, setChangeUser] = useState(0);
  const [setsChange, setSetsChange] = useState(0);
  const [postChange, setPostChange] = useState(0);
  const [mostRecent, setMostRecent] = useState("Wait for actions to happen");
  const [showDotCC, setShowDotCC] = useState(false);
  const [modRequest, setModRequests] = useState<any>([]);
  const [showDotMod, setShowDotMod] = useState(true);
  const ws = new WebSocket(
    "wss://0dq9myca3c.execute-api.eu-west-1.amazonaws.com/prod"
  );

  const getVerificationPending = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRsByStatus?status=PENDING`;
    await fetch(url)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        if (res.items.length > 0) {
          setShowDotCC(true);
        } else {
          setShowDotCC(false);
        }
      });
  };

  const getModerationPending = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationByStatus?status=REASON`;
    await fetch(url)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        if (res.items.length > 0) {
          setShowDotMod(true);
        } else {
          setShowDotMod(false);
        }
      });
  };

  const getStats = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getDatabaseData`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });

        setUsers(data.userNum);
        setSets(data.setNum);
        setPosts(data.postNum);
      })
      .catch((err) => console.log(err));
  };

  const getCountDown = () => {
    const currentTime = new Date().getTime();
    const difference = dateP - currentTime;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days}d:${hours}h:${minutes}m:${seconds}s`;
  };

  useEffect(() => {
    getVerificationPending();
    getModerationPending();
    getStats();
    setInterval(() => {
      setDate(getCountDown());
    }, 1000);

    ws.onopen = () => {
      console.log("connected");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "POST") {
        setPosts(posts + 1);
        setPostChange(postChange + 1);
        setMostRecent(
          `${message.mostRecent.username} just snafled ${message.mostRecent.itemTitle} from the set, ${message.mostRecent.setTitle}`
        );
      } else if (message.type === "USER") {
        setUsers(users + 1);
        setChangeUser(changeUser + 1);
        setMostRecent(`${message.username} just joined Snafler!`);
      } else if (message.type === "SET") {
        setSets(sets + 1);
        setSetsChange(setsChange + 1);
        setMostRecent(
          `${message.mostRecent.username} just created the Snafle Set: ${message.mostRecent.setTitle}`
        );
      } else {
        console.log({ message });
      }
    };
    ws.onerror = (event) => {
      console.log(event);
    };
  }, []);

  const componentWillUnmount = () => {
    ws.close();
  };

  const DataContainer = ({ data }: any): React.ReactElement => {
    return (
      <div className={"navigationItem"} style={{ backgroundColor: data.color }}>
        <div className={"first-row"}>
          <data.Icon size={50} style={{ padding: 0, margin: 0 }} />
          <h3 className={"mission-title-text"}>{data.title}</h3>
          <h1 className={"data-text"}>{data.primaryData}</h1>
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavBarMaintainace />
      <div className={"mission-body-container"}>
        <div className={"mission-data"}>
          <div className={"mission-data-containers-container"}>
            <DataContainer
              data={{
                Icon: FaUsers,
                color: "white",
                title: "Users",
                change: changeUser,
                primaryData: users,
              }}
            />

            <DataContainer
              data={{
                Icon: BiMessageDetail,
                color: "white",
                title: "Posts",
                change: postChange,
                primaryData: posts,
              }}
            />
            <DataContainer
              data={{
                Icon: BsCollectionFill,
                color: "white",
                title: "Sets",
                change: setsChange,
                primaryData: sets,
              }}
            />
          </div>
          <div>
            <Link to="/stats">More Statistics</Link>
          </div>
          <div className={"mission-data-containers-container"}>
            <Link className={"navigationItem"} to="/home">
              <h3>Sets and Items</h3>
            </Link>
            <Link className={"navigationItem"} to="/discoverData">
              <h3>Discover Page</h3>
            </Link>
            <Link className={"navigationItem"} to="/addActivities">
              <h3>Activities</h3>
            </Link>
            <Link className={"navigationItem"} to="/addCategories">
              <h3>Categories</h3>
            </Link>
            <Link className={"navigationItem"} to="/Moderation">
              <h3>Content Moderation</h3>
              {showDotMod && <NotificationDot />}
            </Link>
            <Link className={"navigationItem"} to="/UserFeedback">
              <h3>User Feedback</h3>
            </Link>
            <Link className={"navigationItem"} to="/ContentCreator">
              <h3>Content Creator Requests</h3>
              {showDotCC && <NotificationDot />}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionControl;
