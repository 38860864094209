import { Grid, Select } from "semantic-ui-react";

interface option {
  key: string;
  value: any;
  text: string;
}

interface FilterProps {
  title: string;
  placeholder: string;
  options: option[];
  onChange: (value: any) => void;
  currentValue: any;
}

const IndividalFilter = (props: FilterProps) => {
  const changeHandler = (e: any, { value }: any) => {
    props.onChange(value);
  };

  return (
    <Grid.Column>
      <h4>{props.title}</h4>
      <Select
        search
        placeholder={props.placeholder}
        options={props.options}
        onChange={changeHandler}
        value={props.currentValue}
      />
    </Grid.Column>
  );
};

export default IndividalFilter;
