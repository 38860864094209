import { Dropdown } from "semantic-ui-react";

interface ModeratorCommentsProps {
  currentReportType: string;
  onDropDownChange: (v: any) => void;
  onTextAreaChange: (v: any) => void;
}

const ModeratorComments = (props: ModeratorCommentsProps) => {
  const moderatorComments = () => {
    if (props.currentReportType === "VER") {
      return [
        {
          key: "Meets Requirements",
          text: `Meets Requirements`,
          value: "Meets Requirements",
        },
        {
          key: "Not a Perfect Fit",
          text: `Not a Perfect Fit`,
          value: "Not a Perfect Fit",
        },
        {
          key: "Does not Meet Requirements",
          text: `Does not Meet Requirements`,
          value: "Does not Meet Requirements",
        },
      ];
    } else {
      return [
        {
          key: "FalsePositive",
          text: "False Positive",
          value: "False Positive",
        },
        {
          key: "Inappropiate",
          text: "Inappropriate",
          value: "Inappropriate Content",
        },
        { key: "SexualContent", text: "Sexual", value: "Sexual Content" },
        {
          key: "OffensiveContent",
          text: "Offensive",
          value: "Offensive Content",
        },
      ];
    }
  };

  const dropDownChangeHandler = (event: any, { value }: any) => {
    props.onDropDownChange(value);
  };

  const textAreaChangeHandler = (text: any) => {
    props.onTextAreaChange(text.target.value);
  };

  return (
    <div>
      <h5>Moderator Comments</h5>
      <Dropdown
        placeholder="Moderator Comments"
        fluid
        multiple
        selection
        options={moderatorComments()}
        onChange={dropDownChangeHandler}
      />
      <textarea
        className="mod-textarea"
        placeholder="Any extra comments"
        onChange={textAreaChangeHandler}
        style={{ width: "100%" }}
      ></textarea>
    </div>
  );
};

export default ModeratorComments;
