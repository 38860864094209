import { Button, Form } from "semantic-ui-react";
import Cliploader from "react-spinners/ClipLoader";

const SubmitButton = (props: any) => {
  const onClickHandler = () => {
    props.onSubmit(false);
  };

  return (
    <Form.Field>
      <Button
        style={{
          width: "100%",
          backgroundColor: "var(--primary-color)",
          color: "white",
          borderRadius: 8,
        }}
        type="submit"
        onClick={onClickHandler}
      >
        {props.buttonText}
        <span style={{ padding: "0 1%" }} />
        {!props.isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
      </Button>
    </Form.Field>
  );
};

export default SubmitButton;
