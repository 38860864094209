import { Button } from "semantic-ui-react";
import Cliploader from "react-spinners/ClipLoader";

interface SubmitButtonProps {
  btnText: string;
  onSubmit: () => void;
  isLoaded: boolean;
}

const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <Button
      style={{
        width: "100%",
        backgroundColor: "var(--primary-color)",
        color: "white",
        borderRadius: 8,
      }}
      type="submit"
      onClick={props.onSubmit}
    >
      {props.btnText} <span style={{ padding: "0 1%" }} />
      {!props.isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
    </Button>
  );
};

export default SubmitButton;
