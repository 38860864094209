import Stats from "../components/Stats/stats";
import PageShell from "../components/UI/PageShell/PageShell";

export const StatsScreen = () => {
  return (
    <PageShell title="Stats">
      <Stats />
    </PageShell>
  );
};

export default StatsScreen;
