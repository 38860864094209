import { Grid, Input } from "semantic-ui-react";
import ActivitiesFilter from "../../UI/ReusedFilters/ActivitiesFilter";
import CategoriesFilter from "../../UI/ReusedFilters/CategoriesFilter";
import classes from "./DiscFilterBar.module.css";

interface DiscFilterProps {
  currentCategory: string;
  onCategoryChange: (b: any) => void;
  currentActivity: string;
  onActivityChange: (b: any) => void;
  onSearchChange: (b: any) => void;
  currentSearch: string;
}

const DiscFilterBar = (props: DiscFilterProps) => {
  const SearchChangeHandler = (v: any) => {
    let search = v.target.value;
    console.log(search);
    props.onSearchChange(search);
  };

  return (
    <div className={classes.FilterBar}>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column>
            <h3 className={classes.title}>Filter Selection by</h3>
          </Grid.Column>
          <CategoriesFilter
            currentCategory={props.currentCategory}
            onCategoryChange={props.onCategoryChange}
          />
          <ActivitiesFilter
            currentActivity={props.currentActivity}
            onActivityChange={props.onActivityChange}
          />
          <Grid.Column>
            <div>
              <h4>Search Titles</h4>
              <Input
                placeholder="Search Titles"
                value={props.currentSearch}
                onChange={SearchChangeHandler}
                autoFocus="autoFocus"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default DiscFilterBar;
