import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TableRowMod = (props: {
  username: string;
  userSub: string;
  sourceType: "post" | "set" | "item";
  mediaType: string;
  status: string;
  reason: string;
  moderationTimestamp: string;
  reviewedTimestamp: string;
  modifiedBy: string;
  sourceId: string;
}) => {
  const location = useLocation();

  return (
    <Table.Row key={props.userSub}>
      <Table.Cell>{props.username}</Table.Cell>
      <Table.Cell>{props.sourceType.toUpperCase()}</Table.Cell>
      <Table.Cell>{props.mediaType}</Table.Cell>
      <Table.Cell>{props.reason}</Table.Cell>
      <Table.Cell>{props.moderationTimestamp}</Table.Cell>
      <Table.Cell>{props.reviewedTimestamp}</Table.Cell>
      <Table.Cell>{props.status}</Table.Cell>
      <Table.Cell>
        <Link
          to={`${location.pathname}/MOD_${props.userSub}_${props.sourceId}`}
        >
          View Report
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRowMod;
