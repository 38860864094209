import TableRowReportMOD from "./TableRows/tableRowReportMod";
import TableRowReportVER from "./TableRows/tableRowReportVer";
import TableRowReportREP from "./TableRows/tableRowReportRep";

interface TableRowsProps {
  data: any;
  imageUrl: string;
  currentReportType: string;
}

const TableRowsReport = (props: TableRowsProps): JSX.Element => {
  if (props.currentReportType === "MOD") {
    return <TableRowReportMOD {...props.data} />;
  } else if (props.currentReportType === "VER") {
    return <TableRowReportVER {...props.data} imageUrl={props.imageUrl} />;
  } else {
    return <TableRowReportREP {...props.data} />;
  }
};

export default TableRowsReport;
