import { useState, useEffect } from "react";
import { Table, Dropdown, Button, Tab } from "semantic-ui-react";
import TableRowsVerification from "../tables/tableRowsVerification";

const VerificationTableGeneral = (props: any) => {
  const [dataRaw, setDataRaw] = useState<any>([]);
  const [dataDisplay, setDataDisplay] = useState<any>([]);
  const getVerificationRequests = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=${props.target}`;
    await fetch(url)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setDataRaw(res.items);
        setDataDisplay(res.items);
      });
  };

  const [moderationStatus, setModerationStatus] = useState("ALL");
  const [modTime, setModTime] = useState("NORM");
  useEffect(() => {
    getVerificationRequests();
  }, []);

  const handleModerationTimeSort = (event: any, item: any) => {
    orderTime("timestamp", item.value);
    setModTime(item.value);
  };

  const handleStatusChange = (event: any, item: any) => {
    if (item.value === "ALL") {
      setDataDisplay(dataRaw);
      setModerationStatus("ALL");
      return;
    }
    setModerationStatus(item.value);
    if (modTime !== "NORM") {
      setDataDisplay(dataRaw.filter((x: any) => x.status === item.value));
    } else {
      setDataDisplay(dataRaw.filter((x: any) => x.status === item.value));
    }
  };

  const orderTime = (
    attributeName: string,
    type: "NEWEST" | "OLDEST" | "NORM"
  ) => {
    if (modTime !== "NORM" || moderationStatus !== "ALL") {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => a[attributeName] - b[attributeName]
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => b[attributeName] - a[attributeName]
          )
        );
      }
    } else {
      if (type === "OLDEST") {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => a[attributeName] - b[attributeName]
          )
        );
      } else {
        setDataDisplay(
          dataDisplay.sort(
            (a: any, b: any) => b[attributeName] - a[attributeName]
          )
        );
      }
    }
  };

  return (
    <Tab.Pane>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Request Timestamp
              <div>
                <Dropdown
                  selection
                  fluid
                  options={props.moderationDateSort}
                  value={modTime}
                  onChange={handleModerationTimeSort}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>Requester's Message</Table.HeaderCell>
            <Table.HeaderCell>Request Type</Table.HeaderCell>
            <Table.HeaderCell>
              Request Status
              <div>
                <Dropdown
                  selection
                  fluid
                  options={props.moderationStatusOptions}
                  value={moderationStatus}
                  onChange={handleStatusChange}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>
              View{" "}
              <div>
                <Button
                  primary
                  onClick={() => {
                    setDataDisplay(dataRaw);
                    setModTime("NORM");
                    setModerationStatus("ALL");
                  }}
                >
                  Reset Filters
                </Button>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <TableRowsVerification data={dataDisplay} />
        </Table.Body>
      </Table>
    </Tab.Pane>
  );
};

export default VerificationTableGeneral;
