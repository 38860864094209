import { Form, Input } from "semantic-ui-react";

const AddTitle = (props: any) => {
  const onTitleChange = (val: any) => {
    props.titleChange(val.target.value);
  };

  return (
    <Form.Field required>
      <label>Title</label>
      <Input
        required={true}
        placeholder="Title your Snafle Set"
        value={props.setTitle}
        onChange={onTitleChange}
      />
    </Form.Field>
  );
};

export default AddTitle;
