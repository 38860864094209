import { Form } from "semantic-ui-react";

const FormDropdown = (props: any) => {
  const selectChangeHandler = (val: any) => {
    props.onSelctionChange(val.target.value);
  };

  return (
    <Form.Field required>
      <label>{props.dropLabel}</label>
      <select
        value={props.chosenvalue}
        required={true}
        placeholder={props.placeHolder}
        onChange={selectChangeHandler}
      >
        {
          <option selected disabled>
            {props.placeHolder}
          </option>
        }
        {props.toMap.map((item: any, key: any) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>
    </Form.Field>
  );
};

export default FormDropdown;
