import GeneralCard from "./GeneralCard";

type setObj = { id: string; title: string };

export const Set = (props: {
  item: any;
  dailySets: setObj[];
  dailySetCount: number;
  weeklySets: setObj[];
  newSets: setObj[];
  trendingSets: setObj[];
  addToDaily: (data: setObj) => void;
  addToWeekly: (data: setObj) => void;
  addToTrending: (data: setObj) => void;
  addToNewSets: (data: setObj) => void;
}) => {
  return (
    <GeneralCard targetId={props.item.id}>
      <h2>{props.item.title}</h2>
      <div
        style={{
          maxHeight: "0",
          overflow: "hidden",
          textOverflow: "ellipsis",
          margin: 0,
        }}
      >
        <p style={{ padding: 0, margin: "1% 0" }}>
          {props.item.timedSet ? "timedSet" : ""}
        </p>
      </div>
      <div className="button-group">
        <button
          className="button-discover"
          onClick={(e: any) => {
            props.addToDaily({ id: props.item.id, title: props.item.title });
            e.stopPropagation();
          }}
          disabled={
            props.dailySets.findIndex((v: any) => v.id === props.item.id) >=
              0 || props.dailySetCount === 7
          }
        >
          Daily Sets
        </button>
        <button
          className="button-discover"
          onClick={(e: any) => {
            props.addToWeekly({ id: props.item.id, title: props.item.title });
            e.stopPropagation();
          }}
          disabled={
            props.weeklySets.findIndex((v: any) => v.id === props.item.id) >=
              0 || props.weeklySets.length === 10
          }
        >
          Top Weekly
        </button>
        <button
          className="button-discover"
          onClick={(e: any) => {
            props.addToTrending({ id: props.item.id, title: props.item.title });
            e.stopPropagation();
          }}
          disabled={
            props.trendingSets.findIndex((v: any) => v.id === props.item.id) >=
              0 || props.trendingSets.length === 10
          }
        >
          Trending
        </button>
        <button
          className="button-discover"
          onClick={(e: any) => {
            props.addToNewSets({ id: props.item.id, title: props.item.title });
            e.stopPropagation();
          }}
          disabled={
            props.newSets.findIndex((v: any) => v.id === props.item.id) >= 0 ||
            props.newSets.length >= 10
          }
        >
          New Set
        </button>
      </div>
    </GeneralCard>
  );
};

export default Set;
