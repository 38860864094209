import VerificationTable from "../tables/BlueCheckTableFull";

interface VerificationTabProps {
  moderationDateSort: any;
  moderationStatusOptions: any;
}

const VerrificationTabFragment = (props: VerificationTabProps) => {
  return (
    <VerificationTable
      moderationDateSort={props.moderationDateSort}
      moderationStatusOptions={props.moderationStatusOptions}
    />
  );
};

export default VerrificationTabFragment;
