import React from "react";
import { Tab } from "semantic-ui-react";
import ContentCreatorTabFragment from "./tabs/contentTabFragment";
import AmbassadorTabFragment from "./tabs/ambassadorTabFragment";
import BrandTabFragment from "./tabs/brandTabFragment";
import InfluencerTabFragment from "./tabs/influencerTabFragment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPreviousTabC } from "../../redux/previousContentCreators";
import VerrificationTabFragment from "./tabs/VerificationTabFragment";
import { useState } from "react";

const moderationDateSort = [
  { key: "NEW", value: "NEWEST", text: "NEWEST-OLDEST" },
  { key: "OLDEST", value: "OLDEST", text: "OLDEST-NEWEST" },
  { key: "NORM", value: "NORM", text: "NORM" },
];

const moderationStatusOptions = [
  { key: "ALL", value: "ALL", text: "ALL" },
  {
    key: "REJECTED",
    value: "REJECTED",
    text: "REJECTED",
  },

  {
    key: "ACCEPTED",
    value: "ACCEPTED",
    text: "ACCEPTED",
  },

  {
    key: "PENDING",
    value: "PENDING",
    text: "PENDING",
  },
];

const ContentCreator = () => {
  const dispatch = useAppDispatch();
  const previousTab = useAppSelector(
    (state) => state.contentCreatorPrevious.previousContentCreatorTab
  );

  const TabChangeHandler = (e: any, data: any) => {
    dispatch(setPreviousTabC(data.activeIndex));
  };

  const panes = [
    {
      menuItem: "Ambassador Requests",
      render: () => (
        <AmbassadorTabFragment
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={moderationStatusOptions}
        />
      ),
    },
    {
      menuItem: "Verrification Requests",
      render: () => (
        <VerrificationTabFragment
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={moderationStatusOptions}
        />
      ),
    },
    {
      menuItem: "Content Creator Requests",
      render: () => (
        <ContentCreatorTabFragment
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={moderationStatusOptions}
        />
      ),
    },
    {
      menuItem: "Brands Requests",
      render: () => (
        <BrandTabFragment
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={moderationStatusOptions}
        />
      ),
    },
    {
      menuItem: "Influencer Requests",
      render: () => (
        <InfluencerTabFragment
          moderationDateSort={moderationDateSort}
          moderationStatusOptions={moderationStatusOptions}
        />
      ),
    },
  ];
  return (
    <div className="mod-main-conatiner">
      <Tab
        panes={panes}
        renderActiveOnly={true}
        defaultActiveIndex={previousTab}
        onTabChange={TabChangeHandler}
      />
    </div>
  );
};

export default ContentCreator;
