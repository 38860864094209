import React from "react";
import { Switch, Route } from "react-router-dom";
import { EditItemForm, EditSetForm } from "./components";
import { ActivityImageChooser } from "./components/AddActivities/changeActivityImage.component";
import { CatImageChooser } from "./components/AddCategories/categoryImages.component";
import { ImageChooser, SetImageChooser } from "./components/Images";
import { ItemComponent } from "./components/Item/item.component";
import { LocationComp } from "./components/Location";
import { SetComp } from "./components/Set/set.component";
import { CreateSnafleSet, CreateSnafleItem } from "./components/SetsAndItems";
import MissionControl from "./components/MissionControl/MissionControl";
import ViewReport from "./components/ViewReport/ViewReport";
import ViewUserFeedback from "./components/viewUserFeedback/viewUserFeedback";
import AllUsers from "./components/AllUsers/AllUsers";
import ErrorPage from "./components/404/404";
import {
  SetsAndItems,
  DiscoveryScreen,
  ActivitiesScreen,
  CategoriesScreen,
  ContentModerationScreen,
  UserFeedbackScreen,
  ContentCreatorsScreen,
  StatsScreen,
} from "./Screens";

const Navigator = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/home" component={SetsAndItems} />
        <Route exact path="/stats" component={StatsScreen} />
        <Route exact path="/createSnafleSet" component={CreateSnafleSet} />
        <Route path="/createSnafleSet/setLocation" component={LocationComp} />
        <Route path="/createSnafleSet/setImage" component={ImageChooser} />
        <Route
          exact
          path="/set/:id/createSnafleItem/:title"
          component={CreateSnafleItem}
        />

        <Route path="/editSetImage/:id" component={SetImageChooser} />
        <Route path="/createSnafleItem/setLocation" component={LocationComp} />
        <Route exact path="/set/:id" component={SetComp} />
        <Route exact path="/item/:id" component={ItemComponent} />
        <Route exact path="/editSet/:id" component={EditSetForm} />
        <Route exact path="/editItem/:id" component={EditItemForm} />
        <Route exact path="/editSnafleItem/setImage" component={ImageChooser} />

        <Route exact path="/addCategories" component={CategoriesScreen} />
        <Route exact path="/addActivities" component={ActivitiesScreen} />
        <Route exact path="/discoverData" component={DiscoveryScreen} />

        <Route
          exact
          path="/addCategories/addImage"
          component={CatImageChooser}
        />

        <Route
          exact
          path="/addActivities/addImage"
          component={ActivityImageChooser}
        />

        <Route exact path="/" component={MissionControl} />

        <Route exact path="/Moderation" component={ContentModerationScreen} />
        <Route exact path="/UserFeedback" component={UserFeedbackScreen} />
        <Route exact path="/ContentCreator" component={ContentCreatorsScreen} />

        <Route exact path={`/Moderation/:reportId`} component={ViewReport} />
        <Route exact path={`/UserFeedback/:reportId`} component={ViewReport} />
        <Route
          exact
          path={`/ContentCreator/:reportId`}
          component={ViewReport}
        />

        <Route
          exact
          path={"/viewFeedback/:reportId"}
          component={ViewUserFeedback}
        ></Route>
        <Route exact path="/AllUsers" component={AllUsers} />
        <Route path="/" component={ErrorPage} />
      </Switch>
    </div>
  );
};

export default Navigator;
