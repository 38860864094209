export const AmbassadorCard = (props: {
  item: any;
  ambassadors: {
    id: string;
    title: string;
  }[];
  addToAmbassadors: (data: { id: string; title: string }) => void;
}) => {
  return (
    <div
      className={"set-card"}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${props.item.id}_profile_image.jpg`,
        width: "30%",
      }}
    >
      <h3>{props.item.username}</h3>
      <div className="button-group">
        <button
          className="button-discover"
          onClick={() => {
            props.addToAmbassadors({
              id: props.item.id,
              title: props.item.username,
            });
          }}
          disabled={
            props.ambassadors.findIndex((v: any) => v.id === props.item.id) >=
              0 || props.ambassadors.length === 5
          }
        >
          Add Ambassador
        </button>
      </div>
    </div>
  );
};

export default AmbassadorCard;
